/*accordion*/
app.directive("accordion", ["$rootScope", "$window", function ($rootScope, $window) {
    return {
        restrict: "E",
        transclude: {
            header: "accordionHeader",
            content: "accordionContent"
        },
        scope: {
            isOpen: "=?"
        },
        controller: function ($scope) {
            $scope.isOpen = $scope.isOpen || false;
        },
        templateUrl: function (tElement, tAttrs) {
            return $rootScope.masterPath + "dotcom/templates/" + tAttrs.template + "-accordion.html";
        },
        link: function ($scope, $element, $attrs) {
            var content = $element.find("accordion-content");
            var calcHeight = function () {
                content.parent().css("maxHeight", content[0].offsetHeight + "px");
                if ($attrs.template === "basic") {
                    content.css("paddingLeft", $element.find("accordion-header")[0].offsetLeft + "px");
                }
            };

            //calc height on resize
            angular.element($window).bind("resize", function () {
                if ($scope.isOpen) {
                    calcHeight();
                }
            });

            $scope.$watch("isOpen", function (n) {
                if (n) {
                    calcHeight();
                } else {
                    content.parent().css("maxHeight", 0);
                }
            });

        }
    };
}]);

app.directive("bctPsGlobalJapan", ["sharePointService", function (sharePointService) {
    return {
        restrict: "E",
        template: "<div class='globalJapaneseProductHover'>" +
            "   <div>" +
            "       <span class='subTitle'>In order to visit Products and Services available in Japan </span>" +
            "       <a ng-click='setUserToJapan()'><span>Click Here</span><i class='fa fa-caret-right'></i></a>" +
            "   </div>" +
            "</div>",
        controller: function ($scope) {
            $scope.setUserToJapan = function () {
                sharePointService.setUserCountry('Japan');
            };
        }
    };
}]);


/* global content messaging banner */
app.directive("globalMessage", ["$rootScope", "$window", "$cookies", "$translate", "$document", "sharePointService", function ($rootScope, $window, $cookies, $translate, $document, sharePointService) {
    return {
        restrict: "E",
        scope: true,
        controller: function ($scope) {
            $scope.userLocale = sharePointService.getUserLocale();
            $scope.globalMessageHidden = sharePointService.globalMessageFeatureDisabled();

            $scope.showCountryPicker = function () {
                $rootScope.isCountryPickerShowing = true;
                $scope.hideGlobalMessage();
            };

            $scope.setUserLanguage = function (language) {
                sharePointService.setUserGlobalAndLanguage(language);
            };

            $scope.setJapan = function() {
                sharePointService.setJapan();
            };

            $scope.hideGlobalMessage = function () {
                $scope.globalMessageHidden = true;
                sharePointService.hideGlobalMessage();
            };

            if (!$rootScope.isAnonymous) {
                sharePointService.requestUserProfile().then(function () {
                    var p = sharePointService.getUserProfile();
                    if ((p !== null) && (p.profile.country !== null)) {
                        $scope.hideGlobalMessage();
                    }
                });
            }

            if (!$scope.globalMessageHidden) {
                document.querySelector("#s4-workspace").classList.add("globalMessageOn");
            }

            $scope.$on('hideGlobalMessage', function (event, args) {
                $scope.globalMessageHidden = true;
            });

            // See if the feature toggles have been loaded already
            if ("undefined" !== typeof ($rootScope.featureToggles)) {
                if ($rootScope.featureToggles["Force Global Message"]) {
                    if (sharePointService.forceGlobalMessage()) {
                        $scope.globalMessageHidden = false;
                    }
                }
            }

            // if not, listen for the event that they've been loaded
            $scope.$on('featureTogglesLoaded', function (event, args) {
                if ($rootScope.featureToggles["Force Global Message"]) {
                    if (sharePointService.forceGlobalMessage()) {
                        $scope.globalMessageHidden = false;
                    }
                }
            });
        }
    };
}]);

/*account modal*/
app.directive("accountModal", ["$rootScope", "$document", "sharePointService", "$translate", function ($rootScope, $document, sharePointService, $translate) {
    return {
        restrict: "E",
        controller: function ($scope) {

            //function to set active index
            $scope.setActive = function (i) {
                $scope.active = i;
            };

            //set to 0 on load
            $scope.setActive(0);

            //function to set URL for sharepoint buttons
            $scope.setUrl = sharePointService.setUrl;

            //function to close modal
            $scope.hideModal = function () {
                $rootScope.userShow = false;
            };

            //get languages for modal
            $scope.languages = sharePointService.getLanguages();

            //country change evnet listener
            $scope.$on("country_change", function (e, v) {
                $scope.contactInformation.country = v;
            });


            //if user isnt anonymous
            if (!$scope.isAnonymous) {

                //get user profile
                sharePointService.requestUserProfile().then(function () {
                    var p = sharePointService.getUserProfile();

                    //if user has country, get nav for that country
                    if (sharePointService.hasCountry() && p.profile.country.length) {
                        sharePointService.getNav(p.profile.country);
                    } else {
                        //show the dropdown to select country
                        $rootScope.isCountryPickerShowing = true;
                    }

                    //set the user contact info
                    $scope.contactInformation = p.profile;
                    $scope.contactPreferences = p.preferences;
                });

            } else {
                if (sharePointService.hasCountry()) {
                    //get the nav from country existing in localStorage
                    sharePointService.getNav();
                }

                //set contact info
                $scope.contactInformation = {
                    country: sharePointService.getUserCountry()
                };
            }

            //hide modal and show country modal
            $scope.showCountryPickerDropDown = function () {
                $rootScope.userShow = false;
                $scope.changeCountry();
            };

            //button to change country
            $scope.changeCountry = function () {
                $rootScope.isCountryPickerShowing = true;
            };

            //function to save languages
            $scope.saveLanguage = function (val) {
                var flag = false;
                angular.forEach($scope.contactPreferences.languages, function (v) {
                    if (v.label === val.label) {
                        flag = true;
                    }
                });
                if (!flag) {
                    $scope.contactPreferences.languages.push(val);
                }
            };

            //delete language
            $scope.deleteElement = function (i) {
                $scope.contactPreferences.languages.splice(i, 1);
            };

            //move language order
            $scope.moveElement = function (from, to) {
                if (to < 0 || to === $scope.contactPreferences.languages.length) {
                    return;
                }
                $scope.contactPreferences.languages.splice(to, 0, $scope.contactPreferences.languages.splice(from, 1)[0]);
            };

        },
        scope: {
            links: "=",
            isAnonymous: "="
        },
        link: function ($scope, $element) {

            //completely hide the footer if not instantiated
            if (!$element.find("modal-footer").contents().length) {
                $element.find("modal-footer").css("display", "none");
            }

            //modal content div
            var modalContent = $element.find("modal-content");

            var modalPage = $element.find("modal-page");

            //set width to length * 100
            modalContent.css("width", modalPage.length * 100 + "%");

            //set width to length / 100
            modalPage.css("width", 100 / modalPage.length + "%");

            //function to close
            var modalClick = function (e) {
                if (e.target.id === "account-modal") {
                    $rootScope.userShow = false;
                    $rootScope.$digest();
                }
            };

            //watch show boolean for disabling background scroll
            var shown = false;
            $scope.$watch("$root.userShow", function (n) {
                if (n) {
                    angular.element($document[0].body).addClass("disable-scroll");
                    $element.bind("click", modalClick);
                    shown = true;
                } else {
                    if (shown) {
                        $scope.editLanguage = false;
                        $scope.showChangeCountry = false;
                        if (!$scope.isAnonymous) {
                            sharePointService.setUserProfile($scope.contactPreferences);
                        }
                    }
                    angular.element($document[0].body).removeClass("disable-scroll");
                    $element.unbind("click", modalClick);

                }
            });

            //last page active
            $scope.lastVal = 0;

            //transition between pages
            $scope.$watch("active", function (n, o) {
                if (n === o) {
                    return;
                }
                var val = (o - n) + $scope.lastVal;
                $scope.lastVal = val;
                angular.element(modalContent).css("transform", "translate3d(" + ($scope.lastVal * (100 / modalPage.length)) + "%,0,0)");
            });
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/account-modal.html"
    };
}]);

/*card row (for tomes software pages)*/
app.directive("cardRow", ["$window", function ($window) {
    return {
        restrict: "A",
        scope: true,
        link: function ($scope, $element) {
            var getHeight = function () {
                var tallestCardHeight = 0;
                var cards = $element[0].getElementsByClassName("card");
                angular.forEach(cards, function (v) {
                    if (v.clientHeight > tallestCardHeight) {
                        tallestCardHeight = v.clientHeight;
                    }
                });

                angular.element(cards).css("height", tallestCardHeight + "px");
            };
            $element.ready(function () {
                getHeight();
            });
            //calc height on resize
            angular.element($window).bind("resize", function () {
                getHeight();
            });
        }
    };
}]);

/*main carousel*/
app.directive("carouselNew", ["$rootScope", "$document", "$window", "$timeout", "$interval", "$translate", "arrayService", function ($rootScope, $document, $window, $timeout, $interval, $translate, arrayService) {
    return {
        restrict: "A",
        scope: true,
        controller: function ($scope) {

            //watch screen size to restart slide if window is < 549
            $scope.$watch("$root.isMobileLayout", function (n) {
                $scope.showProgress = n;
                if ($scope.transition) {
                    $scope.transition();
                }
            });

            //function to render and return raw html
            $scope.arrayService = arrayService;

            // $scope.images comes from the <json-data> tag in the html
            //$scope.images = [];
        },
        link: function ($scope, $element) {

            // set up the html function
            angular.forEach($scope.images, function (image) {
                image.html = function () {
                    var htmlStr = '';
                    htmlStr += '<a href="' + image.linkSrc + '" class="content-header carousel-header" style="border-color:' + image.color + ';bottom:0;" target="_self">';
                    htmlStr += '<h1 class="no-margin"><span class="regular">' + image.title1 + '</span> ' + image.description1 + '<br/>' + image.description2 + '<i class="fa fa-long-arrow-right" style="color:' + image.color + '"></i></h1>';
                    htmlStr += '</a>';
                    return htmlStr;
                }
            });

            //get progressbar for mobile
            var progressBar = angular.element($element[0].getElementsByClassName("main-progress")[0]);

            //get carousel images
            var carouselImages = angular.element($element.children()[0]);
            carouselImages.css("height", $element[0].clientWidth * (9 / 16) + "px");

            //get each image thumbnail
            var imageThumbs = $element[0].getElementsByClassName("image-thumb");

            //get middle thumbnail table element
            var carouselThumbTable = angular.element($element[0].getElementsByClassName("carousel-thumbs")[0]);

            //get the whole thumbnail table wrapper
            var carouselThumbsWrapper = angular.element($element[0].getElementsByClassName("carousel-thumbs-wrapper")[0]);

            //how wide should the images be
            var imgWidth = 100 / $scope.images.length;

            //boolean to determine slide direction
            var slideForward = true;

            var cells = $element[0].getElementsByClassName("carousel-thumbs-cell");
            //function to calculate height
            var calcHeight = function () {

                var height = angular.element(carouselImages[0]).children()[0].clientHeight;
                carouselImages.css("height", height + "px");
                carouselThumbsWrapper.css("width", (($scope.images.length / 5) * (carouselThumbTable[0].clientWidth * 0.9)) + "px");
                angular.element(imageThumbs).css("width", imgWidth - 2 + "%");
                carouselThumbTable.css("height", imageThumbs[0].clientWidth + "px");
                angular.element(imageThumbs).css("height", imageThumbs[0].clientWidth + "px");

                var padding = cells[0].offsetLeft + cells[1].offsetLeft;
                angular.element($document[0].getElementById("upper-template")).css({
                    paddingTop: $document[0].getElementsByClassName("main-header")[0].clientHeight + "px",
                    paddingLeft: padding + "px",
                    paddingRight: padding + "px"
                });
            };

            //function to start interval
            $scope.start = function () {
                $scope.int = $interval(function () {
                    $scope.slide(slideForward);
                }, 10000);
            };

            //function to cancel interval
            $scope.cancel = function () {
                $interval.cancel($scope.int);
            };

            //function to transition to new image
            $scope.transition = function () {

                //cancel interval
                $scope.cancel();

                //remove active image
                carouselImages.children().removeClass("active");

                //remove active thumb
                angular.element(imageThumbs).removeClass("active");

                //remove active class to make progress slide
                progressBar.removeClass("active");

                //set quick timeout
                var t = $timeout(function () {

                    //if mobile view (isPhone), activate progress
                    if ($scope.showProgress) {
                        progressBar.addClass("active");
                    }

                    //add active to the first iamge
                    angular.element(carouselImages.children()[0]).addClass("active");

                    //add active to the first thumb
                    angular.element(imageThumbs[0]).addClass("active");

                    //add click event
                    carouselThumbTable.css("pointer-events", "auto");
                    $timeout.cancel(t);
                }, 750);

                //restart interval
                $scope.start();
            };

            //function to slide to new image
            $scope.slide = function (forward, count) {

                if (count === 0) {
                    return;
                }

                //disable click events
                carouselThumbTable.css("pointer-events", "none");

                //if backwards
                if (!forward) {

                    //set slideForward to false
                    slideForward = false;

                    //remove the last array element and put it on the front
                    $scope.images.unshift($scope.images.pop());

                    //remove transition animation
                    carouselThumbsWrapper.removeClass("transition");

                    //set position to look visually correct
                    carouselThumbsWrapper.css("transform", "translate3d(" + -imgWidth + "%,0,0)");

                    //set a quick timeout
                    var t1 = $timeout(function () {

                        //re-add transition for slide effect
                        carouselThumbsWrapper.addClass("transition");

                        //slide it
                        carouselThumbsWrapper.css("transform", "translate3d(0,0,0)");

                        //call transition function
                        $scope.transition();
                        $timeout.cancel(t1);
                    }, 250);
                } else { //if forward

                    //if count doesnt exist, set it to 1
                    count = count || 1;

                    //slideForward true
                    slideForward = true;

                    //add transition class
                    carouselThumbsWrapper.addClass("transition");

                    //and move to the slide desired
                    carouselThumbsWrapper.css("transform", "translate3d(" + -(imgWidth * count) + "%,0,0)");

                    //get number of how many images to add
                    var num = Math.abs($scope.images.length - ($scope.images.length - 1) - count);

                    //set time dynamically based on amount of images needing to be added
                    var t = Math.round(500 / count);

                    //first counter
                    var c1 = 0;

                    //set interval
                    var int = $interval(function () {

                        //before halfway thru the timer, remove show class for all images that will be spliced out of array
                        if (c1 <= num) {
                            angular.element(imageThumbs[c1]).removeClass("show");
                        }

                        //halfway point
                        if (c1 === num * 2) {

                            //remove elements from beginning of array and put them on the end
                            $scope.images = $scope.images.concat($scope.images.splice(0, count));

                            //remove transition class
                            carouselThumbsWrapper.removeClass("transition");

                            //quickly shift the wrapper position back to 0
                            carouselThumbsWrapper.css("transform", "translate3d(0,0,0)");

                            //count for last half of interval
                            var c2 = ($scope.images.length - 1) - num;

                            //interval to add each new image after appended to end of array
                            var int2 = $interval(function () {
                                angular.element(imageThumbs[c2]).addClass("show");
                                if (c2 === ($scope.images.length - 1)) {
                                    calcHeight();
                                    $interval.cancel(int2);
                                    return;
                                }
                                c2++;
                            }, t);

                            //call new transition
                            $scope.transition();
                            $interval.cancel(int);
                            return;
                        }
                        c1++;
                    }, t);
                }
            };

            $element.ready(function () {

                //calc height on load
                calcHeight();

                //calc height on resize
                angular.element($window).bind("resize", function () {
                    calcHeight();
                });

                //transition when ready
                $scope.transition();

            });

        }
    };
}]);

//country picker drop down
app.directive('countryPickerDropDown', ["$rootScope", "$window", "$interval", function ($rootScope, $window, $interval) {
    return {
        controller: "ctrlCountryPickerDropDown",
        restrict: "E",
        scope: {
            regionalizedcountries: '=regionalizedcountries'
        },
        link: function ($scope, element, attrs) {
            var setPickerHeight = function (showPicker) {
                if (showPicker) {
                    var panelHeight = (window.GetViewportHeight()) - (document.querySelector('.logoAndTagline').offsetHeight);
                    element.css('height', panelHeight + 'px');
                    element[0].querySelector('.pickerParent').style.height = panelHeight + 'px';
                } else {
                    element.css('height', '0px');
                }
            };

            angular.element($window).bind("scroll", function () {
                setPickerHeight($rootScope.isCountryPickerShowing);
                $scope.$digest();
            });
            angular.element($window).bind("resize", function () {
                setPickerHeight($rootScope.isCountryPickerShowing);
                $scope.$digest();
            });
            element.ready(function () {
                $scope.$watch("$root.isCountryPickerShowing", function (n) {
                    if (n && !$scope.templateUrl) {
                        $scope.templateUrl = "/_catalogs/masterpage/dotcom/templates/country-picker-drop-down.html"
                    }

                    if (n) {
                        if (element[0].querySelector('.pickerParent') === null) {
                            $scope.int = $interval(function () {
                                if (element[0].querySelector('.pickerParent') !== null) {
                                    $interval.cancel($scope.int);
                                    setPickerHeight(!!n);
                                }
                            }, 250);
                        } else {
                            setPickerHeight(!!n);
                            $interval.cancel($scope.int);
                        }
                    } else {
                        setPickerHeight(!!n);
                        $interval.cancel($scope.int);
                    }
                });
            });
        },

        template: '<ng-include src="getTemplateUrl()"/>'
    };
}]);

/*feature carousel*/
app.directive("featureCarousel", ["$rootScope", "$window", function ($rootScope, $window) {
    return {
        restrict: "E",
        templateUrl: $rootScope.masterPath + "dotcom/templates/feature-carousel.html",
        controller: function ($scope) {

            //start at 0
            $scope.imgIndex = 0;

            //change index function
            $scope.indexChange = function (i) {
                if (i < 0 || i >= $scope.featurePages.length) {
                    return;
                }
                $scope.imgIndex = i;
            };
        },
        scope: {
            color: "@",
            links: "="
        },
        transclude: true,
        link: function ($scope, $element) {

            //get carousel
            var carousel = $element.find("feature-carousel-wrapper");

            var slider = $element.find("slider");

            $scope.featurePages = $element.find("feature-page");

            //function to calculate height of image/data
            var calcHeight = function () {
                carousel.css("height", $scope.featurePages[$scope.imgIndex].clientHeight + "px");
            };


            //when element is ready
            $element.ready(function () {
                var featureLink = $element.find("feature-link");

                //function to calculate slider position
                var calcSlider = function (i) {
                    slider.css({
                        "width": featureLink[i].clientWidth + "px",
                        "marginLeft": featureLink[i].offsetLeft - 20 + "px",
                        "top": featureLink[i].offsetTop + featureLink[i].clientHeight - 5 + "px"
                    });
                };

                //set width (100 * images length)
                carousel.css("width", ($scope.featurePages.length * 100) + "%");

                //set image width
                carousel.children().css("width", (100 / $scope.featurePages.length) + "%");

                //watch for index change and slide
                $scope.$watch("imgIndex", function (n) {
                    carousel.css("transform", "translate3d(" + -(100 / $scope.featurePages.length) * n + "%,0,0)");
                    calcHeight();
                    calcSlider(n);
                });

                //calc height on load
                calcHeight();

                //calculate slider
                calcSlider($scope.imgIndex);

                //calc height on resize
                angular.element($window).bind("resize", function () {
                    calcHeight();
                    calcSlider($scope.imgIndex);
                });

            });

        }
    };
}]);

/*font size (for press release pages)*/
app.directive("fontSize", ["$document", function ($document) {
    return {
        restrict: "A",
        scope: true,
        link: function ($scope, $element) {

            $element.ready(function () {

                var fontSize = 1;
                var text = angular.element($document[0].getElementsByClassName("article-content")[0]).contents();
                $element.children().bind("click", function () {
                    fontSize = this.attributes["size-up"].value == "true" ? fontSize += 0.1 : fontSize -= 0.1;
                    angular.forEach(text, function (v) {
                        if (v.style) {
                            angular.element(v).css("font-size", fontSize + "em");
                        }
                    });
                });
            });
        },
    };
}]);

/*footer*/
app.directive("footer", ["$rootScope", "$translate", "sharePointService", function ($rootScope, $translate, sharePointService) {

    return {
        restrict: "A",
        scope: true,
        controller: function ($scope) {
            $scope.loadTranslations = function () {
                $translate(['MP_FOOTER_CONTRACT_MANUFACTURING', 'MP_FOOTER_CAREERS',
                    'MP_FOOTER_EMP_RESOURCES', 'MP_FOOTER_RESEARCH_GRANTS',
                    'MP_FOOTER_AA_AWARD', 'MP_FOOTER_PRIVACY_POLICY', 'MP_FOOTER_TERMS_OF_USE',
                    'MP_FOOTER_TERMS_OF_TRADE', 'MP_FOOTER_TERMS_CONDITIONS',
                    'MP_FOOTER_SITE_MAP'
                ]).then(function (translations) {
                    $scope.userLocale = sharePointService.getUserLocale();
                    var localePath = "";
                    if ($scope.userLocale !== "en-us") {
                        //capture both "zh, ja and other locales"
                            localePath = "/" + $scope.userLocale;
                    }
                    if ($scope.userLocale != "ja") {
                        $scope.footerRow = {
                            row1: [{
                                name: translations.MP_FOOTER_CONTRACT_MANUFACTURING,
                                link: localePath + "/contract-manufacturing"
                            }, {
                                name: translations.MP_FOOTER_CAREERS,
                                link: localePath + "/careers"
                            }, {
                                name: translations.MP_FOOTER_EMP_RESOURCES,
                                link: "/Pages" + localePath + "/Employee-Resources.aspx"
                            }],
                            row2: [{
                                name: translations.MP_FOOTER_RESEARCH_GRANTS,
                                link: localePath + "/about-us/research-grants"
                            }, {
                                name: translations.MP_FOOTER_AA_AWARD,
                                link: "/advancingapheresis/"
                            }],
                            row3: [{
                                name: translations.MP_FOOTER_PRIVACY_POLICY,
                                link: "/Pages" + localePath + "/Privacy-Policy.aspx"
                            }, {
                                name: translations.MP_FOOTER_TERMS_CONDITIONS,
                                link: "/Pages" + localePath + "/Terms.aspx"
                            }, {
                                name: translations.MP_FOOTER_SITE_MAP,
                                link: "/Pages" + localePath + "/Site-Map.aspx"
                            }]
                        };
                    } else {
                        $scope.footerRow = {
                            row1: [{
                                name: translations.MP_FOOTER_EMP_RESOURCES,
                                link: "/Pages" + localePath + "/Employee-Resources.aspx"
                            }],
                            row2: [{
                                name: translations.MP_FOOTER_AA_AWARD,
                                link: "/advancingapheresis/"
                            }],
                            row3: [{
                                name: translations.MP_FOOTER_PRIVACY_POLICY,
                                link: "/Pages" + localePath + "/Privacy-Policy.aspx"
                            }, {
                                name: translations.MP_FOOTER_SITE_MAP,
                                link: "/Pages" + localePath + "/Site-Map.aspx"
                            }]
                        };
                    }
                });
            };

            $scope.$on('translation-loaded', function () {
                $scope.loadTranslations();
            });
        },
        link: function ($scope) {

            $scope.footerRow = {
                row1: [{
                    name: "Contract Manufacturing",
                    link: "/contract-manufacturing"
                }, {
                    name: "Careers",
                    link: "/careers"
                }, {
                    name: "Employee Resources",
                    link: "/Pages/Employee-Resources.aspx"
                }],
                row2: [{
                    name: "Research & Grants",
                    link: "/about-us/research-grants"
                }, {
                    name: "Advancing Apheresis Award",
                    link: "https://www.terumobct.com/advancingapheresis/"
                }],
                row3: [{
                    name: "Terms of Use",
                    link: "/Pages/Terms-of-Use.aspx"
                }, {
                    name: "Terms of Trade",
                    link: "/Pages/Terms.aspx"
                }, {
                    name: "Terms and Conditions",
                    link: "/Pages/Terms.aspx"
                }, {
                    name: "Site Map",
                    link: "/Pages/Site-Map.aspx"
                }]
            };
        }
    };
}]);

app.directive("elqBanner", ["$rootScope", "$cookies", "$translate", function ($rootScope, $cookies, $translate) {
    return {
        restrict: "C",
        controller: function ($scope) {

        }
    };
}]);

/*main header*/
app.directive("terumoMainHeader", ["$document", "$window", "$rootScope", "$timeout", "$translate", "sharePointService", function ($document, $window, $rootScope, $timeout, $translate, sharePointService) {
    return {
        restrict: "A",
        priority: 100000,
        scope: true,
        link: function ($scope, $element) {
            if (!$scope.isAnonymous) {
                sharePointService.requestUserProfile().then(function () {
                    var p = sharePointService.getUserProfile();
                    $scope.userName = p.profile.FirstName + " " + p.profile.LastName;
                    if (sharePointService.hasCountry()) {
                        var country = sharePointService.getUserCountry();
                        $scope.countryName = sharePointService.getUserCountry();

                        if ($rootScope.CountryCodeArray === undefined) {
                            sharePointService.requestCountryInfo().then(function (resp) {
                                Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                    if (item.name.toLowerCase() === country.toLowerCase()) {
                                        $scope.countryCode = item.countryCode;
                                    }
                                });
                            });
                        } else {
                            Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                if (item.name.toLowerCase() === country.toLowerCase()) {
                                    $scope.countryCode = item.countryCode;
                                }
                            });
                        }
                        //Final Sanity check to determine if country code has a value
                        if ($scope.countryCode === undefined) {
                            $scope.countryCode = "Global"
                        }

                    }
                });
            } else {
                $scope.userName = null;

                if (sharePointService.hasCountry()) {
                    var country = sharePointService.getUserCountry();
                    $scope.countryName = sharePointService.getUserCountry();

                    if (!sharePointService.isGlobalContent()) {

                        if ($rootScope.CountryCodeArray === undefined) {
                            sharePointService.requestCountryInfo().then(function (resp) {
                                Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                    if (item.name.toLowerCase() === country.toLowerCase()) {
                                        $scope.countryCode = item.countryCode;
                                    }
                                });
                            });
                        } else {
                            Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                if (item.name.toLowerCase() === country.toLowerCase()) {
                                    $scope.countryCode = item.countryCode;
                                }
                            });
                        }

                    } else {
                        $scope.countryCode = "Global";
                    }
                    $rootScope.isGlobalContent = sharePointService.isGlobalContent();
                }
            }
            var navDD = $element[0].getElementsByClassName("nav-dropdown");
            var rollover = $element[0].getElementsByClassName("rollover");
            var rolloverRightSide = $element[0].getElementsByClassName("ps-right");
            var getNavDDHeight = function (h) {
                var baseHeight = $window.innerHeight - $element[0].getElementsByClassName("leftSide")[0].clientHeight;
                if (document.querySelector("#s4-workspace.globalMessageOn")) {
                    angular.element(navDD).css("maxHeight", (baseHeight - 50) + "px");
                } else {
                    angular.element(navDD).css("maxHeight", baseHeight + "px");
                }
                var rolloverAndRightSideHeight = $window.innerHeight - $document[0].getElementById("Site-Header").clientHeight;
                angular.element(rollover).css("maxHeight", rolloverAndRightSideHeight + "px");
                angular.element(rolloverRightSide).css("maxHeight", (rolloverAndRightSideHeight - 1) + "px");
            };
            $element.ready(function () {
                getNavDDHeight();
            });

            //set index
            $scope.setPSIndex = function (i) {
                $scope.masterIndex = i;
            };

            //country change evnet listener
            $scope.$on("country_change", function (e, v) {
                if (sharePointService.hasCountry()) {
                    var country = sharePointService.getUserCountry();
                    $scope.countryName = sharePointService.getUserCountry();

                    if (!sharePointService.isGlobalContent()) {
                        if ($rootScope.CountryCodeArray === undefined) {
                            sharePointService.requestCountryInfo().then(function (resp) {
                                Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                    if (item.name.toLowerCase() === country.toLowerCase()) {
                                        $scope.countryCode = item.countryCode;
                                    }
                                });
                            });
                        } else {
                            Array.forEach($rootScope.CountryCodeArray, function (item, index) {
                                if (item.name.toLowerCase() === country.toLowerCase()) {
                                    $scope.countryCode = item.countryCode;
                                }
                            });
                        }
                    } else {
                        $scope.countryCode = "Global";
                    }
                }
                $rootScope.isGlobalContent = sharePointService.isGlobalContent();
            });

            //show user modal
            $scope.showOrLoginUser = function (userName) {
                if (userName) {
                    $rootScope.userShow = !$rootScope.userShow;
                } else {
                    sharePointService.setUrl();
                }
            };

            //show search window
            $scope.showSearch = function () {
                $rootScope.searchShow = !$rootScope.searchShow;
            };

            //disable body scrolling on hamburger click
            $scope.$watch("hamburgerClick", function (n) {
                if (n) {
                    angular.element($document[0].body).addClass("disable-scroll");
                } else {
                    angular.element($document[0].body).removeClass("disable-scroll");
                }
            });
            angular.element($window).bind("resize", function () {
                getNavDDHeight();
                if (this.innerWidth >= 780 && $scope.hamburgerClick) {
                    $scope.hamburgerClick = false;
                    $scope.$apply();
                }
            });

        },
        controller: function ($scope) {

            //index for non products and services
            $scope.nonPsIndex = null;

            //index for products and services
            $scope.psIndex = null;
            //click for mobile nav products and services
            $scope.psClick = false;

            //master index for rollover
            $scope.masterIndex = 0;

            $scope.productClick = function (idx, masterIdx, dataArr) {

                //if products and services is clicked, toggle all nonPS
                if (masterIdx === null) {
                    angular.forEach(dataArr, function (v) {
                        v.clicked = false;
                    });
                    $scope.psClick = !$scope.psClick;
                    return;
                }

                //if any nonPs is clicked, toggle products/services
                if (masterIdx === "nonPsIndex") {
                    $scope.psClick = false;
                }

                //if the user clicks on the same field to toggle it
                if (idx === $scope[masterIdx]) {
                    dataArr[idx].clicked = !dataArr[idx].clicked;
                    $scope[masterIdx] = null;
                    return;
                }

                //toggle from true to false
                angular.forEach(dataArr, function (v, i) {
                    if (i !== idx) {
                        v.clicked = false;
                    } else {
                        v.clicked = true;
                    }
                });

                //re-assign index
                $scope[masterIdx] = idx;
            };
        }
    };
}]);

/*light box */
app.directive("lightBox", ["$rootScope", function ($rootScope) {
    return {
        scope: {
            url: "@",
            innerPic: "@"
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/lightbox.html",
        controller: function ($scope) {
            $scope.show = false;
        },
        link: function ($scope, $element) {

            $element.bind("click", function (e) {

                if (!$scope.show) {
                    $scope.show = true;
                } else {
                    if (e.target.className.indexOf("lb") === -1) {
                        $scope.show = false;
                    }
                }
                $scope.$apply();
            });

        }
    };
}]);

app.directive("modal", ["$document", "$rootScope", function ($document, $rootScope) {
    return {
        restrict: "E",
        controller: function ($scope) {

            //function to set active index
            $scope.setActive = function (i) {
                $scope.active = i;
            };

            //set to 0 on load
            $scope.setActive(0);

            //function to close modal
            $scope.hideModal = function () {
                $scope.show = false;
            };

        },
        transclude: {
            footer: "?modalFooter",
            content: "?modalContent",
            title: "?modalTitle"
        },
        scope: {
            show: "=",
            disableClose: "=?"
        },
        link: function ($scope, $element) {

            //if any video/iframes exist in modals
            var video = $element.find("terumo-video");
            var videoSrc = null;

            //if video exists, set the source
            if (video.length) {
                videoSrc = video.attr("source");
            }
            //function to set video source
            var setVideoSrc = function (src) {
                if (video.length) {
                    video.find("iframe").attr("src", src);
                }
            };

            //if no header
            if (!$element.find("modal-title").length) {
                $element.find("modal-header").css("display", "none");
            }

            //completely hide the footer if not instantiated
            if (!$element.find("modal-footer").contents().length) {
                $element.find("modal-footer").css("display", "none");
            }

            //modal content div
            var modalContent = $element.find("modal-content");

            //get the modal page
            var modalPage = $element.find("modal-page");

            //set width to length * 100
            modalContent.css("width", modalPage.length * 100 + "%");

            //set width to length / 100
            modalPage.css("width", 100 / modalPage.length + "%");

            //off click event
            var modalClick = function (e) {
                if (!$scope.disableClose) {
                    if (e.target.id === "basic-modal") {
                        $scope.show = false;
                        $scope.$apply();
                    }
                }
            };


            //watch show boolean for disabling background scroll
            $scope.$watch("show", function (n) {
                if (n) {
                    angular.element($document[0].body).addClass("disable-scroll");
                    $element.bind("click", modalClick);
                    setVideoSrc(videoSrc);
                } else {
                    angular.element($document[0].body).removeClass("disable-scroll");
                    $element.unbind("click", modalClick);
                    setVideoSrc("");
                }
            });

            //last page active
            $scope.lastVal = 0;

            //transition between pages
            $scope.$watch("active", function (n, o) {
                if (n === o) {
                    return;
                }
                var val = (o - n) + $scope.lastVal;
                $scope.lastVal = val;
                angular.element(modalContent).css("transform", "translate3d(" + ($scope.lastVal * (100 / modalPage.length)) + "%,0,0)");
            });
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/modal.html"
    };
}]);

//text on left, image on right (entirely clickable)
app.directive("newsRow", ["$window", function ($window) {
    return {
        restrict: "E",
        scope: {
            src: "@",
            color: "@",
            href: "@"
        },
        template: '<a ng-href="{{href}}" ng-class="{\'no-underline\':!href}"><news-row-cell><img ng-src="{{src}}" class="full-width kbe" /></news-row-cell><news-row-cell ng-style="{\'border-color\':color}" class="left" ng-transclude=""></news-row-cell></a>',
        transclude: true,
        link: function ($scope, $element) {
            $element.ready(function () {
                var leftCell = $element.find("news-row-cell")[1];
                var rightCell = $element.find("news-row-cell")[0];
                var calcHeight = function () {
                    angular.element(leftCell).css("minHeight", rightCell.clientHeight + "px");
                };
                calcHeight();
                angular.element($window).bind("resize", function () {
                    calcHeight();
                });
            });
        }
    };
}]);

//multiple links on left, image on right
app.directive("tabRow", ["$window", function ($window) {
    return {
        restrict: "E",
        scope: {
            src: "@",
            color: "@"
        },
        template: '<div class="tab-rows"><tab-row-cell><img ng-src="{{src}}" class="full-width kbe" /></tab-row-cell><tab-row-cell ng-style="{\'border-color\':color}" class="left" ng-transclude=""></tab-row-cell></div>',
        transclude: true,
        link: function ($scope, $element) {
            $element.ready(function () {
                var leftCell = $element.find("tab-row-cell")[1];
                var rightCell = $element.find("tab-row-cell")[0];
                var calcHeight = function () {
                    angular.element(leftCell).css("minHeight", rightCell.clientHeight + "px");
                };
                calcHeight();
                angular.element($window).bind("resize", function () {
                    calcHeight();
                });
            });
        }
    };
}]);

/*sliding display for protocols page*/
app.directive("slidingDisplay", ["$rootScope", "$timeout", "$document", "$window", "sharePointService", function ($rootScope, $timeout, $document, $window, sharePointService) {
    return {
        transclude: {
            menu: "slidingMenu",
            content: "slidingContent"
        },
        scope: {
            data: "=",
            selectTitle: "@"
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/sliding-display.html",
        link: function ($scope, $element) {

            //time to animate between clicks
            var animationTime = 500;

            //get the title (left side)
            var titles = $element.find("sliding-display-title");

            //left side div
            var menu = $element.find("sliding-display-menu");

            //right side div
            var content = $element.find("sliding-display-content");

            //get the info (right side)
            var info = $element.find("sliding-display-info");

            //reference to last clicked element
            var last = null;

            //index of clicked element
            $scope.dataIndex = Number(sharePointService.getUrlVars()["idx"]) || 0;

            //function to calc height of content
            var calcHeight = function (index) {
                if (!info[index]) {
                    return;
                }
                var menuHeight = info[index].clientHeight <= menu[0].clientHeight ? menu[0].clientHeight : info[index].clientHeight;
                $element.css("height", menuHeight + $document[0].getElementById("select-header").clientHeight + "px");
                content.css("height", menuHeight + "px");
            };

            //when user clicks on left side
            $scope.slide = function (index) {

                //disable click events

                $element.css("pointer-events", "none");

                //add class to clicked on element
                angular.element(titles[index]).addClass("grey-background");

                //remove grey background from last title
                if (last !== null) {
                    angular.element(titles[last]).removeClass("grey-background");
                }

                //remove content
                angular.element(info[last]).removeClass("show");

                //toggle slide
                var t = $timeout(function () {
                    calcHeight(index);

                    //add content
                    var t2 = $timeout(function () {
                        angular.element(info[index]).addClass("show");
                        $element.css("pointer-events", "auto");
                        $scope.dataIndex = last = index;
                        $timeout.cancel(t2);
                    }, animationTime);
                    $timeout.cancel(t);
                }, animationTime);
            };

            //click event for left side (not sure why this wasnt ng-click)
            for (var i = 0; i < titles.length; i++) {
                (function (index) {
                    angular.element(titles[i]).bind("click", function () {
                        if (last === index) {
                            return;
                        }
                        $scope.slide(index);
                    });
                })(i);
            }

            //control height of content on load
            $element.ready(function () {
                $scope.slide($scope.dataIndex);
                var t3 = $timeout(function () {
                    angular.element($document[0].getElementsByClassName("order-info")[0]).addClass("show");
                    $timeout.cancel(t3);
                }, 1500);
            });


            angular.element($window).bind("resize", function () {
                calcHeight(last);
            });
        }
    };
}]);

/*scroll listener to collapse header*/
app.directive("scroller", ["$document", "$window", "$rootScope", function ($document, $window, $rootScope) {
    return {
        scope: true,
        link: function ($scope, $element) {

            var getHeight = function () {
                angular.element($document[0].getElementById("s4-bodyContainer")).css("marginTop", ($element.find("sticky-header").length ? $element.find("sticky-header")[0].clientHeight : 0) + $document[0].getElementById("Site-Header").clientHeight + "px");
                angular.element($window).bind("resize", function () {
                    angular.element($document[0].getElementById("s4-bodyContainer")).css("marginTop", ($element.find("sticky-header").length ? $element.find("sticky-header")[0].clientHeight : 0) + $document[0].getElementById("Site-Header").clientHeight + "px");
                });
            };
            if (angular.element($document[0].getElementsByClassName("image-wrapper")[0]).length && !angular.element($document[0].getElementsByClassName("image-wrapper")[0]).find("img").length && $rootScope.other) {
                getHeight();
                $rootScope.collapse = true;

            } else if (!angular.element($document[0].getElementsByClassName("image-wrapper")[0]).length && $window.pageLayoutName !== "home") {
                getHeight();
            } else {
                //function to collapse header based on scroll position
                var collapse = function () {
                    if (this.pageYOffset >= 100) {
                        $rootScope.collapse = true;
                    } else {
                        $rootScope.collapse = false;
                    }
                    $rootScope.$digest();
                };

                //under 780, dont allow header to uncollapse
                $rootScope.collapse = $rootScope.isMobileLayout;

                //if its a phone or not
                $rootScope.isPhone = $element[0].clientWidth > 549 ? false : true;
                angular.element($window).bind("resize", function () {
                    $rootScope.collapse = this.pageYOffset >= 100 || $element[0].clientWidth < 780 ? true : false;
                    $rootScope.isPhone = $element[0].clientWidth > 549 ? false : true;
                    $rootScope.$digest();
                });

                //watch isMobileLayout
                $scope.$watch("$root.isMobileLayout", function (n) {
                    if (!n) {
                        angular.element($window).bind("scroll", collapse);
                    } else {
                        angular.element($window).unbind("scroll", collapse);
                    }
                });
            }

        }
    };
}]);

//scroll to onClick event
app.directive("scrollTo", ["$document", "smoothScroll", function ($document, smoothScroll) {
    return {
        scope: {
            clickingOn: "@",
            scrollingTo: "@"
        },
        link: function ($scope, $element) {

            $element.ready(function () {
                var clickingOn = $document[0].getElementsByClassName($scope.clickingOn);
                var scrollingTo = $document[0].getElementsByClassName($scope.scrollingTo);
                for (var i = 0; i < clickingOn.length; i++) {
                    (function (index) {
                        clickingOn[i].onclick = function () {
                            var pos = scrollingTo[index].offsetTop + $document[0].getElementsByClassName("scroll-container")[0].offsetTop - $document[0].getElementById("Site-Header").clientHeight - $document[0].getElementsByTagName("STICKY-HEADER")[0].clientHeight;
                            smoothScroll(pos);
                        };
                    })(i);
                }
            });
        }
    };
}]);

/*search*/
app.directive("search", ["$document", "$window", "$rootScope", "sharePointService", "$http", "$timeout", "$translate", function ($document, $window, $rootScope, sharePointService, $http, $timeout, $translate) {
    return {
        restrict: "E",
        controller: function ($scope) {

            //declare user, productCode, categoryCode, segmentCode
            $scope.user = null;
            $scope.productCode = null;
            $scope.categoryCode = null;
            $scope.segmentCode = null;

            //function to update products in list
            $scope.updateProducts = function (v) {
                var role = $scope.user ? $scope.user.profile.role : "Anonymous";
                v = v || '*';
                $http({
                    method: 'GET',
                    headers: {
                        Accept: "application/json;odata=nometadata"
                    },
                    url: "/_api/search/query?querytext='" + v + "(BCTRoles:" + role + " AND BCTCountries:" + '"' + sharePointService.getUserCountry() + '"' + ")'&Refiners=%27BCTProducts%27&RowLimit=0&SourceId=%272484d4cc-d497-42b7-a0df-a799412d3974%27&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'"
                }).success(function (resp) {

                    if (resp.PrimaryQueryResult.RefinementResults && resp.PrimaryQueryResult.RefinementResults.Refiners.length) {
                        $scope.products = resp.PrimaryQueryResult.RefinementResults.Refiners[0].Entries;
                    }
                });
            };

            //function to update categories
            $scope.updateCategories = function (v) {
                var role = $scope.user ? $scope.user.profile.role : "Anonymous";
                v = v || '*';
                $http({
                    method: 'GET',
                    headers: {
                        Accept: "application/json;odata=nometadata"
                    },
                    url: "/_api/search/query?querytext='" + v + "(BCTRoles:" + role + " AND BCTCountries:" + '"' + sharePointService.getUserCountry() + '"' + ")'&Refiners=%27BCTCategory%27&RowLimit=0&SourceId=%272484d4cc-d497-42b7-a0df-a799412d3974%27&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'"
                }).success(function (resp) {
                    if (resp.PrimaryQueryResult.RefinementResults && resp.PrimaryQueryResult.RefinementResults.Refiners.length) {
                        $scope.categories = resp.PrimaryQueryResult.RefinementResults.Refiners[0].Entries;
                    }
                });
            };

            //function to update segments
            $scope.updateSegments = function (v) {
                var role = $scope.user ? $scope.user.profile.role : "Anonymous";
                v = v || '*';
                $http({
                    method: 'GET',
                    headers: {
                        Accept: "application/json;odata=nometadata"
                    },
                    url: "/_api/search/query?querytext='" + v + "(BCTRoles:" + role + " AND BCTCountries:" + '"' + sharePointService.getUserCountry() + '"' + ")'&Refiners=%27BCTSegments%27&RowLimit=0&SourceId=%272484d4cc-d497-42b7-a0df-a799412d3974%27&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'"
                }).success(function (resp) {
                    if (resp.PrimaryQueryResult.RefinementResults && resp.PrimaryQueryResult.RefinementResults.Refiners.length) {
                        $scope.segments = resp.PrimaryQueryResult.RefinementResults.Refiners[0].Entries;
                    }
                });
            };

            //function to create URL string to send to sharepoint
            $scope.updateFields = function (v) {

                var customObj = {
                    "k": "*",
                    "r": []
                };

                var str = "";

                if ($scope.productCode) {
                    str += "* BCTProducts:" + $scope.productCode.RefinementToken;
                    customObj.r.push({
                        "n": "BCTProducts",
                        "t": [$scope.productCode.RefinementToken],
                        "o": "and",
                        "m": null,
                        "k": false
                    });
                }
                if ($scope.categoryCode) {
                    str += "* BCTCategory:" + $scope.categoryCode.RefinementToken;
                    customObj.r.push({
                        "n": "BCTCategory",
                        "t": [$scope.categoryCode.RefinementToken],
                        "o": "and",
                        "m": null,
                        "k": false
                    });
                }
                if ($scope.segmentCode) {
                    str += "* BCTSegments:" + $scope.segmentCode.RefinementToken;
                    customObj.r.push({
                        "n": "BCTSegments",
                        "t": [$scope.segmentCode.RefinementToken],
                        "o": "and",
                        "m": null,
                        "k": false
                    });
                }
                var newStr = str.length > 0 ? str : null;

                var countryString = $scope.isAnonymous ? "Country=" + sharePointService.getUserCountry() + "&" : "";

                $scope.newLink = str.length > 0 ? "/search/Pages/all.aspx?" + countryString + "k=*#Default=" + JSON.stringify(customObj) : null;

                if (!$scope.productCode) {
                    $scope.updateProducts(newStr);
                }
                if (!$scope.categoryCode) {
                    $scope.updateCategories(newStr);
                }
                if (!$scope.segmentCode) {
                    $scope.updateSegments(newStr);
                }
            };

            //go to SP search
            $scope.goToLocation = function (v) {
                var countryString = $scope.isAnonymous ? "Country=" + sharePointService.getUserCountry() + "&" : "";
                if (v) {
                    $window.location.href = '/search/Pages/all.aspx?' + countryString + "k=" + v;
                }
            };

            //show user profile from search menu
            $scope.goToAccount = function () {
                $rootScope.searchShow = false;
                var t = $timeout(function () {
                    $rootScope.userShow = true;
                    $timeout.cancel(t);
                }, 500);

            };

            //get search suggestions from sharepoint
            $scope.getSuggestions = function (e, v) {
                if (e.which === 13 && v) {
                    $scope.goToLocation(v);
                    return;
                }
                if (v.length > 1) {
                    sharePointService.getSuggestions(v).then(function (resp) {
                        $scope.suggestions = resp.data.Queries;
                    });
                }
            };
        },
        scope: true,
        link: function ($scope, $element) {
            var iconSelector = null;
            var topMargin = null;
            var scootTriangle = null;

            var assignIsMobileLayout = function () {
                $rootScope.isMobileLayout = $window.innerWidth < 780;
            };

            //function to calculate where the top/left of the search box should be
            var calculateTop = function () {
                var globalMessageHeight = document.querySelector("global-message").offsetHeight;
                switch ($rootScope.isMobileLayout) {
                    case true:
                        iconSelector = ".search-link-parent-mobile";
                        topMargin = 12;
                        scootTriangle = 6;
                        break;

                    default:
                        iconSelector = ".search-link-parent";
                        topMargin = 0;
                        scootTriangle = -8;
                        break;
                }
                var icon = document.querySelector(iconSelector);
                if (icon) {
                    var top = globalMessageHeight + icon.offsetTop + icon.offsetHeight + topMargin;
                    angular
                        .element($element.children()[0])
                        .css("top", top + "px");
                    var left = icon.offsetLeft;
                    $element
                        .find("triangle")
                        .css("left", left + scootTriangle + "px");
                }
            };

            //resize event
            angular.element($window).bind("resize", function () {
                calculateTop();
                assignIsMobileLayout();
                $rootScope.$digest();
            });

            //off click search to close it
            var onClick = function (e) {
                if (e.target.tagName === "SEARCH") {
                    $rootScope.searchShow = false;
                    $rootScope.$digest();
                }
            };

            //watch show boolean for disabling background scroll
            $scope.$watch("$root.searchShow", function (n) {
                if (n) {
                    if (!$rootScope.isAnonymous) {
                        sharePointService.requestUserProfile().then(function () {
                            $scope.user = sharePointService.getUserProfile();
                        });
                    }
                    angular.element($document[0].body).addClass("disable-scroll");

                    $element.bind("click", onClick);
                    calculateTop();

                    $scope.updateProducts();
                    $scope.updateCategories();
                    $scope.updateSegments();

                } else {
                    angular.element($document[0].body).removeClass("disable-scroll");
                    $element.unbind("click", onClick);
                }
            });


            //show the icon when its ready
            $element.ready(function () {
                angular.element($document[0].getElementsByClassName("fa-search")[0]).addClass("show");
            });

        }
    };
}]);

/*stickyheader */
app.directive("stickyHeader", ["$rootScope", "$window", "$document", "smoothScroll", "$timeout", "arrayService", function ($rootScope, $window, $document, smoothScroll, $timeout, arrayService) {
    return {
        restrict: "E",
        scope: {
            color: "=",
            sliderColor: "@",
            permanentDisable: "="
        },
        transclude: {
            heading: "stickyHeading",
            links: "?stickyLinks"
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/sticky-header.html",
        link: function ($scope, $element) {

            //color for the slider
            $scope.sliderColor = $scope.sliderColor || "#ffffff";

            //index (should start at the first)
            var sliderIndex = 0;

            //slight margin for making sure the slider will always be in the right div
            var slightMargin = 10;

            //calculate the pages margin top
            var pageMarginTop = function () {
                if ($document[0].getElementById("s4-bodyContainer")) {
                    return Number($window.getComputedStyle($document[0].getElementById("s4-bodyContainer")).marginTop.replace(/\D+/g, ''));
                }
                return 0;
            };

            //background opacity for the sticky header
            var backgroundOpacity = 0.9;

            //each product section (overview, features, benefits, lit)
            var productSection = $document[0].getElementsByClassName("product-section");

            //slider element
            var sliderBar = $element.find("slider");

            //function to calculate the slider position
            var calcSlider = function (i) {
                if (!$scope.permanentDisable) {
                    sliderBar.css({
                        "width": stickyLinks[i].clientWidth + "px",
                        "marginLeft": stickyLinks[i].offsetLeft - 20 + "px",
                        "top": stickyLinks[i].offsetTop + stickyLinks[i].clientHeight - 5 + "px"
                    });
                }
            };

            //each child of the product header
            var stickyLinks = $element.find("sticky-link");

            //disable slide boolean (disable scroll sliding when click)
            var disableSlide = false;

            //set background
            $element.css("background", arrayService.hexToRgb($scope.color, backgroundOpacity));

            //if image in header has text in it (elutra, quantum RUO)
            var mainImageText = $document[0].getElementsByClassName("main-image-text");

            //padding for the bottom of the text (sitting about the sticky header)
            var bottomPadding = 20;

            //function to set the text
            var setTextObj = function () {

                angular.forEach(mainImageText, function (v) {
                    angular.element(v).css({
                        marginLeft: $document[0].getElementsByClassName("container")[0].offsetLeft + "px",
                        top: ($element[0].offsetTop - v.clientHeight - bottomPadding) + "px"
                    });

                    angular.element(v).addClass("text-show");
                });
            };

            //if there is text in the image
            if (mainImageText.length) {
                setTextObj();
            }

            //on ready
            $element.ready(function () {

                //calculate slider position if there are links
                if (stickyLinks.length) {
                    calcSlider(sliderIndex);
                }

                //on window scroll
                var position = $window.pageYOffset;
                angular.element($window).bind("scroll", function () {

                    //condition to hide or show the header depending on scroll direction (up = show, down = hide)
                    if (!disableSlide) {
                        if (this.pageYOffset > 50 && this.pageYOffset > position) {
                            $element.addClass("hide-header");
                        } else {
                            $element.removeClass("hide-header");
                        }
                    }

                    //fix product header and show slider
                    if ($element.parent().find("img")[0]) {
                        if (this.pageYOffset >= $element[0].offsetTop - $document[0].getElementById("Site-Header").clientHeight) {
                            $element.addClass("fix-to-top");
                        }

                        if ($element.parent().find("img")[0].getBoundingClientRect().bottom >= $element[0].clientHeight + $document[0].getElementById("Site-Header").clientHeight) {
                            $element.removeClass("fix-to-top");
                        }
                    }

                    //reference parent scope
                    var that = this;

                    //slide if user scrolls (but doesnt click)
                    if (!disableSlide) {
                        angular.forEach(productSection, function (v, i) {
                            var imgHeight = $element.parent().find("img")[0] ? $element.parent().find("img")[0].clientHeight : 0;
                            var collapsedNavHeight = $rootScope.isMobileLayout ? 60 : 100;
                            var divPos = v.offsetTop + imgHeight - $element[0].clientHeight - collapsedNavHeight + slightMargin + pageMarginTop();
                            if (that.pageYOffset >= divPos && that.pageYOffset < (divPos + v.clientHeight)) {
                                if (stickyLinks.length) {
                                    calcSlider(i);
                                }
                                sliderIndex = i;
                            }
                        });
                    }
                    position = this.pageYOffset;
                }).bind("resize", function () {

                    if (stickyLinks.length) {
                        var t = $timeout(function () {
                            calcSlider(sliderIndex);
                            $timeout.cancel(t);
                        }, 250);
                    }

                    if (mainImageText.length) {
                        setTextObj();
                    }
                });

                //for each product child, attach click event that slides slider
                var lastTimer = null;
                angular.forEach(stickyLinks, function (v, i) {
                    (function (index) {
                        angular.element(v).bind("click", function () {
                            if (lastTimer) {
                                $timeout.cancel(lastTimer);
                            }
                            disableSlide = true;
                            sliderIndex = index;
                            calcSlider(index);
                            var imgHeight = $element.parent().find("img")[0] ? $element.parent().find("img")[0].clientHeight : 0;
                            var collapsedNavHeight = $rootScope.isMobileLayout ? 60 : 100;
                            var newPos = productSection[index].offsetTop + imgHeight - $element[0].clientHeight - collapsedNavHeight + slightMargin + pageMarginTop();
                            smoothScroll(newPos, null, function () {
                                lastTimer = $timeout(function () {
                                    disableSlide = false;
                                    $timeout.cancel(lastTimer);
                                }, 333);
                            });
                        });
                    })(i);
                });

                //if hash
                if ($window.location.hash) {
                    var hash = $window.location.hash.substring(2).toLowerCase();
                    if ($document[0].getElementById(hash)) {
                        var t = $timeout(function () {
                            var imgHeight = $element.parent().find("img")[0] ? $element.parent().find("img")[0].clientHeight : 0;
                            var collapsedNavHeight = $rootScope.isMobileLayout ? 60 : 100;
                            var newPos = $document[0].getElementById(hash).offsetTop + imgHeight - $element[0].clientHeight - collapsedNavHeight + slightMargin + pageMarginTop();
                            smoothScroll(newPos, null, function () {
                                disableSlide = false;
                                $timeout.cancel(t);
                            });
                        }, 200);
                    }
                }
            });
        }
    };
}]);

//sliding select menu for eloqua forms
app.directive("slidingSelect", ["$rootScope", "$translate", function ($rootScope, $translate) {
    return {
        require: "^ngModel",
        scope: {
            bindedModel: "=ngModel",
            bindedClass: "@class",
            required: "=",
            options: "=",
            enableOther: "="
        },
        templateUrl: $rootScope.masterPath + "dotcom/templates/sliding-select.html",
        link: function ($scope, $element, $attrs, ngModel) {

            //array to store the choices
            var tempArray = [];

            //how the user will see the separated values
            var separator = "; ";

            //when user clicks on the input
            $scope.focus = function () {
                $scope.show = true;
            };

            //function to add choices to tempArray
            $scope.addToArray = function (option) {
                option.selected = !option.selected;
                tempArray = [];
                angular.forEach($scope.options, function (v) {
                    if (v.selected) {
                        tempArray.push(v.title);
                    }
                });

                $scope.bindedModel = tempArray.join(separator);
            };

            //text input for 'other'
            $scope.otherText = "";

            //add element to predefined options
            $scope.addElement = function (t) {
                if (!t.length) {
                    return;
                }
                $scope.options.push({
                    title: t
                });
                $scope.addToArray($scope.options[$scope.options.length - 1]);
            };

            //add selected items on load
            $element.ready(function () {
                if (ngModel.$viewValue) {
                    angular.forEach(ngModel.$viewValue.split(separator), function (v) {
                        var found = false;
                        angular.forEach($scope.options, function (v2) {
                            if (v === v2.title) {
                                v2.selected = true;
                                found = true;
                            }
                        });
                        if (!found) {
                            $scope.addElement(v);
                        }
                    });
                }
            });
        }
    };
}]);

/*support image (for support page)*/
app.directive("supportImage", ["$window", function ($window) {
    return {
        restrict: "A",
        scope: true,

        link: function ($scope, $element) {
            var calcSize = function () {
                var tallestHeight = 0;
                angular.forEach($element.children().children().children(), function (v) {
                    if (v.clientHeight > tallestHeight) {
                        tallestHeight = v.clientHeight;
                    }
                });
                $element.children().children().css("height", tallestHeight + "px");
            };

            $element.ready(function () {
                calcSize();
            });

            angular.element($window).bind("resize", function () {
                calcSize();
            });
        }
    };
}]);

/*table ad*/
app.directive("tableAdvertisement", ["$window", function ($window) {
    return {
        restrict: "E",
        transclude: true,
        template: '<a ng-href="{{href}}" ng-transclude></a>',
        scope: {
            href: "@"
        },
        link: function ($scope, $element) {
            $element.ready(function () {

                //get the first image in the row (usually a one-third)
                var firstImage = $element.parent().parent().parent().find("img")[0];

                //find the table cell
                var cells = $element.find("table-advertisement-cell");

                //function to calculate size
                var calcSize = function () {
                    cells.css({
                        height: firstImage.clientHeight + "px",
                        width: $element.parent()[0].clientWidth / 2 + "px"
                    });
                };

                calcSize();

                angular.element($window).bind("resize", function () {
                    calcSize();
                });
            });
        }
    };
}]);

/*text image*/
app.directive("textImage", ["$rootScope", "$window", function ($rootScope, $window) {
    return {
        restrict: "E",
        scope: {
            src: "@",
            href: "@"
        },
        transclude: true,
        templateUrl: $rootScope.masterPath + "dotcom/templates/text-image.html",
        link: function ($scope, $element) {

            //get the text
            var text = $element[0].getElementsByClassName("product-image-text")[0];

            //get the image
            var img = $element.find("img")[0];

            //if the text takes up too much of the image, drop it below the image instead
            var calcTextTop = function () {
                if (img.clientHeight - text.clientHeight <= 75) {
                    angular.element(text).addClass("add-static");
                } else {
                    angular.element(text).removeClass("add-static");
                }
            };

            $element.ready(function () {
                calcTextTop();
            });

            angular.element($window).bind("resize", function () {
                calcTextTop();
            });
        }
    };
}]);

/*video*/
app.directive("terumoVideo", ["$window", "$sce", function ($window, $sce) {
    return {
        restrict: "E",
        controller: function ($scope) {

            //get url and convert it
            $scope.url = $sce.trustAsResourceUrl($scope.source);
        },
        scope: {
            source: "@"
        },
        template: '<iframe width="100%" height="{{height}}" ng-src="{{url}}" frameborder="0" allowfullscreen></iframe>',
        link: function ($scope, $element) {

            //maintain aspect ratio
            $scope.height = (9 / 16) * $element.children()[0].clientWidth;

            //resize event
            angular.element($window).bind("resize", function () {
                $scope.height = (9 / 16) * $element.children()[0].clientWidth;
                $scope.$apply();
            });
        }
    };
}]);

//button to go to top
app.directive("toTop", ["$window", "$document", "smoothScroll", function ($window, $document, smoothScroll) {
    return {
        restrict: "E",
        scope: {
            section: "@"
        },
        template: '<i class="fa fa-chevron-up"></i>',
        link: function ($scope, $element) {

            //get section
            var section = $document[0].getElementById($scope.section);

            //show/hide on scroll
            angular.element($window).bind("scroll", function () {
                if (this.pageYOffset > $document[0].getElementsByClassName("scroll-container")[0].offsetTop + section.offsetTop - $document[0].getElementById("Site-Header").clientHeight - $document[0].getElementsByTagName("STICKY-HEADER")[0].clientHeight) {
                    $element.addClass("show");
                } else {
                    $element.removeClass("show");
                }
            });

            //have element show on load if user is down on page
            $window.scrollTo(0, $window.pageYOffset - 1);

            //bind click event
            $element.bind("click", function () {
                smoothScroll($document[0].getElementsByClassName("scroll-container")[0].offsetTop + section.offsetTop - $document[0].getElementById("Site-Header").clientHeight - $document[0].getElementsByTagName("STICKY-HEADER")[0].clientHeight);
            });
        }
    };
}]);