var app = app;
var angular = angular;

app.service("sharePointService", ["$rootScope", "$window", "$http", "$q", "$document", "$cookies", "$translate", function ($rootScope, $window, $http, $q, $document, $cookies, $translate) {

    // because ngCookies.get() returns "undefined", not "null"
    var getCookieValueOrNull = function (cookieName) {
        var val = $cookies.get(cookieName);
        if (val !== undefined) {
            return val;
        } else {
            return null;
        }
    };

    var getUrlVars = function () {
        var vars = {};
        $window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value.split("#")[0];
        });
        return vars;
    };

    var checkForParams = function (url) {
        var arr = url.split('?');
        if (arr[1]) {
            return arr[0] + "?Country=" + this.getUserCountry() + "&" + arr[1];
        }

        var arr2 = url.split('#');
        if (arr2[1]) {
            return arr2[0] + "?Country=" + this.getUserCountry() + "&k=*#" + arr2[1];
        }
        return url + "?Country=" + this.getUserCountry() + "&k=*";
    };

    var getSupportTopics = function () {
        return $http.get('/_catalogs/masterpage/dotcom/data/' + getUserLocale() + '/supporttopics.json.txt');
    };

    //if user has country
    var hasCountry = function () {
        return getCookieValueOrNull("userCountry");
    };

    var globalMessageFeatureDisabled = function () {
        // Don't show the global message if the user is authenticated unless the feature is enabled
        if ("undefined" !== typeof($rootScope.featureToggles)) {
            if (!isAnonymous() && !($rootScope.featureToggles["Global Country Selection"])) {
                return true;
            }
        }
        
        return (getCookieValueOrNull("hideGlobalMessage") === "true") ? true : false;
    };

    var hideGlobalMessage = function () {
        if (!globalMessageFeatureDisabled()) {
            $rootScope.$broadcast('hideGlobalMessage');
        }

        if ("undefined" !== typeof ($rootScope.featureToggles)) {
            if ($rootScope.featureToggles["Force Global Message"]) {
                document.cookie = "featureForceGlobalMessageDone=true; max-age=31556926; path=/;domain=" + document.domain;
            }
        }

        document.cookie = "hideGlobalMessage=true; max-age=31556926; path=/;domain=" + document.domain;
        document.querySelector("#s4-workspace").classList.remove("globalMessageOn");
    };

    var forceGlobalMessage = function () {
        if ((getCookieValueOrNull("featureForceGlobalMessageDone") === "true") ? true : false) {
            return false;
        }
        if (!isAnonymous()) {
            return false;
        }
        // Force clear the user's locale and country
        document.cookie = "userCountry=Global; max-age=31556926; path=/;domain=" + document.domain;
        $cookies.remove("userLocale");
        document.cookie = "hideGlobalMessage=false; max-age=31556926; path=/;domain=" + document.domain;
        document.cookie = "featureForceGlobalMessageDone=true; max-age=31556926; path=/;domain=" + document.domain;
        $window.location.reload();
        return true;
    }

    var isAnonymous = function () {
        return _spPageContextInfo.userId === undefined;
    };

    //handle Global button click
    $rootScope.$on('featureTogglesLoaded', function () {

        var result = getCookieValueOrNull("userLocale");
        //the cookie value hasn't been set so try to figure out the location of the user. 
        if (result === null) {

            //if this feature is not turned on don't check for the browser language
            if ("undefined" !== typeof ($rootScope.featureToggles)) {

                //grab the language/locale language of the browser
                var lang = navigator.languages && navigator.languages[0] ||
                    navigator.language ||
                    navigator.userLanguage || "";

                if ($rootScope.featureToggles["Global Chinese Selection"]) {
                    //check for simplified chinese
                    if (lang.toLowerCase().substring(0, 2) == "zh") {
                        setUserLocale("zh");
                    }
                }
                if ($rootScope.featureToggles["Global Japanese Selection"]) {
                    //check for japanese
                    if (lang.toLowerCase().substring(0, 2) == "ja") {
                        setUserLocale("ja");
                    }
                }
            }

        }
    });

    var getUserLocale = function () {

        var result = getCookieValueOrNull("userLocale");
        //the cookie value hasn't been set so try to figure out the location of the user. 
        if (result !== null) {
            return result;
        }

        // Check to see if they have a local storage value that was previously used
        result = localStorage.getItem("userLocale");
        if (result !== null) {
            return result;
        }

        //default to english 
        return "en-us"
    };

    var setUserLocale = function (locale) {
        var _locale;
        if (!locale) {
            _locale = getUserLocale();
        } else {
            _locale = locale;
        }
        var today = new Date();

        var options = {
            path: "/",
            expires: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
        };
        $cookies.put("userLocale", _locale, options);
        switchLanguageCSS(_locale);
        $translate.use(_locale);
    };

    //get the users country
    var getUserCountry = function () {
        var result = getCookieValueOrNull("userCountry");
        if (result !== null) {
            return result;
        }

        result = localStorage.getItem("userCountry");
        if (result !== null) {
            document.cookie = "userCountry=" + result + "; max-age=31556926; path=/;domain=" + document.domain;
            return result;
        }

        return null;
    };

    var isGlobalContent = function () {
        var country = this.getUserCountry();
        if (country === null) {
            return undefined;
        } else if (country.toLowerCase() === "global") {
            return true;
        } else {
            return false;
        }
    };

    //set the users country

    var setUserCountry = function (country, skipRedirect) {
        // in case user is switching from Global Chinese/Japanese to anything else
        if ((country.toLowerCase() !== "global" && country.toLowerCase() !== "japan") && ((this.getUserLocale() === "zh") || (this.getUserLocale() === "ja"))) {
            this.setUserLocale("en-us");
        }
        document.cookie = "userCountry=" + country + "; max-age=31556926; path=/;domain=" + document.domain;
        this.getNav(country);
        if (!$rootScope.isAnonymous) {
            $http({
                url: _spPageContextInfo.siteAbsoluteUrl + "/_vti_bin/BCT.Ext.Services/MyProfileService.svc/SaveMyProfile/v2",
                method: "POST",
                headers: {
                    "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                    "Content-Type": "application/json; odata=nometadata"
                },
                data: JSON.stringify({
                    Country: country
                })
            });
        }
        $rootScope.$broadcast("country_change", country);
        $rootScope.isCountryPickerShowing = false;

        if (!skipRedirect) {
            //set window back to the default page
            window.location.href = (window.location.origin);
        }
    };

    var userProfile = null;
    var getKeyValueFromProfileResponse = function (key, response) {
        var keyMatches = response.data.UserProfileProperties.filter(function (item) {
            return item.Key === key;
        });
        if (keyMatches.length > 0) {
            var value = keyMatches[0].Value;
            if (value !== undefined && value != null) {
                return value;
            }
        }
        return "";
    };

    var requestUserProfile = function () {
        var self = this;
        if (self.getUserProfile()) {
            // don't call the SharePoint API more times than necessary
            return $q(function(resolve, reject) {
                resolve(self.getUserProfile());
            });
        }

        // See if there's an Auth0 role in the profile
        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/SP.UserProfiles.PeopleManager/GetMyProperties",
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=nometadata"
            }
        }).then(function (r) {
            var userLanguages = [];
            var muiLanguages = getKeyValueFromProfileResponse("SPS-MUILanguages", r);
            if (typeof (muiLanguages) == "string") {
                muiLanguages = muiLanguages.split(',');
            }

            angular.forEach(muiLanguages, function (v) {
                angular.forEach(self.getLanguages(), function (v2) {
                    if (v2.code === v) {
                        userLanguages.push(v2);
                    }
                });
            });

            userProfile = {
                profile: {
                    UserName: getKeyValueFromProfileResponse("UserName", r),
                    country: getKeyValueFromProfileResponse("country", r),
                    EmailAddress: getKeyValueFromProfileResponse("EmailAddress", r),
                    FirstName: getKeyValueFromProfileResponse("FirstName", r),
                    LastName: getKeyValueFromProfileResponse("LastName", r),
                    organization: getKeyValueFromProfileResponse("organization", r),
                    role: getKeyValueFromProfileResponse("role", r)
                },
                preferences: {
                    languages: userLanguages,
                    receiveEmails: getKeyValueFromProfileResponse("receiveEmails", r)
                }
            };

            var authrole = getKeyValueFromProfileResponse("authrole", r);
            if (authrole !== undefined && authrole != null && authrole !== "") {
                userProfile.profile.role = authrole;
            }

            if (!userProfile.profile.country.length) {
                $cookies.remove("userCountry");
            } else {
                var options = {
                    path: "/"
                }
                //$cookies.put("userCountry", r.data.Country, options);
                if (typeof country != 'undefined') {
                    document.cookie = "userCountry=" + country + "; max-age=31556926; path=/;domain=" + document.domain;
                } else {
                    if (userProfile.profile.country.length > 0) {
                        document.cookie = "userCountry=" + userProfile.profile.country + "; max-age=31556926; path=/;domain=" + document.domain;
                    } else {
                        document.cookie = "userCountry=Global; max-age=31556926; path=/;domain=" + document.domain;
                    }
                }
            }
        });
    };

    var getUserProfile = function () {
        return userProfile;
    };

    var getSuggestions = function (v) {
        return $http({
            method: "GET",
            headers: {
                Accept: "application/json;odata=nometadata"
            },
            url: "/_api/search/suggest?querytext='" + v + "'"
        });
    };

    var setUserProfile = function (profile) {
        var langs = [];
        angular.forEach(profile.languages, function (v) {
            langs.push(v.code);
        });
        $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_vti_bin/BCT.Ext.Services/MyProfileService.svc/SaveMyProfile/v2",
            method: "POST",
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Content-Type": "application/json; odata=nometadata"
            },
            data: JSON.stringify({
                PreferredLanguages: langs
            })
        });
    };

    var getNav = function (c) {
        //return;
        var country = null;
        if (!c) {
            country = this.getUserProfile() ? this.getUserProfile().profile.country : this.getUserCountry();
        } else {
            country = c;
        }

        if (!country) {
            return;
        }

        var navigationHttp = $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/getbytitle('NavigationLinks')/GetItems(query=@v4)?@v4={\"ViewXml\":\"<View><ViewFields><FieldRef Name='BCTRoles' /><FieldRef Name='Blurb' /><FieldRef Name='hexValue' /><FieldRef Name='isProductOrService' /><FieldRef Name='imagePath' /><FieldRef Name='NavigationLevel' /><FieldRef Name='uniqueID0' /><FieldRef Name='ParentUniqueID' /><FieldRef Name='Title' /><FieldRef Name='URL' /><FieldRef Name='SortOrder' /></ViewFields><Query><Where><And><Neq><FieldRef Name='ExcludedCountries'/><Value Type='TaxonomyFieldTypeMulti'>" + country + "</Value></Neq><And><Eq><FieldRef Name='Locale'/><Value Type='Text'>" + getUserLocale() + "</Value></Eq><Or><Eq><FieldRef Name='AllCountries'/><Value Type='Boolean'>1</Value></Eq><Eq><FieldRef Name='Country'/><Value Type='TaxonomyFieldTypeMulti'>" + country + "</Value></Eq></Or></And></And></Where><OrderBy><FieldRef Name='ParentUniqueID'/><FieldRef Name='SortOrder'/></OrderBy></Query></View>\"}",
            method: "POST",
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=nometadata"
            }
        });

        var obj = {
            productsAndServices: [],
            allOtherData: []
        };

        var level1Items = {};
        var level2Items = {};
        var level3Items = {};
        var level4Items = {};

        var userRole = "Anonymous";
        if (!$rootScope.isAnonymous) {
            var self = this;
            this.requestUserProfile().then(function () {
                userRole = self.getUserProfile() ? self.getUserProfile().profile.role : "Anonymous";
            });
        }

        return $q.all([navigationHttp]).then(function (results) {

                angular.forEach(results[0].data.value, function (v) {
                    var roleMatched = false;
                    if (v.BCTRoles.length > 0) {
                        angular.forEach(v.BCTRoles, function (definedRole) {
                            if (definedRole.Label == userRole) {
                                roleMatched = true;
                            }
                        });
                    } else {
                        roleMatched = true;
                    }

                    if (roleMatched) {
                        switch (v.NavigationLevel) {

                            case "Level1":
                                level1Items[v.uniqueID0] = v;

                                if (v.isProductOrService) {
                                    obj.productsAndServices.push(v);
                                } else {
                                    obj.allOtherData.push(v);
                                }

                                v.navLinkGroups = [];
                                break;

                            case "Level2":
                                level2Items[v.uniqueID0] = v;
                                break;

                            case "Level3":
                                level3Items[v.uniqueID0] = v;
                                v.navLinkItems = [];
                                break;

                            case "Level4":
                                level4Items[v.uniqueID0] = v;
                                break;

                            default:
                                // nothing
                        }
                    }
                });

                angular.forEach(level2Items, function (level2Item) {
                    var parent = level1Items[level2Item.ParentUniqueID];
                    if (typeof parent != 'undefined') {
                        parent.navItemPromo = level2Item;
                    }
                });

                angular.forEach(level3Items, function (level3Item) {
                    var parent = level1Items[level3Item.ParentUniqueID];
                    parent.navLinkGroups.push(level3Item);
                });

                angular.forEach(level4Items, function (level4Item) {
                    var parent = level3Items[level4Item.ParentUniqueID];
                    parent.navLinkItems.push(level4Item);
                });

                $rootScope.psData = obj.productsAndServices;
                $rootScope.nonPsData = obj.allOtherData;
                return obj;

            }
            /*, function (e) {
                        alert("Error!");
                    }*/
        );
    };

    var requestFeatureToggles = function () {

        var self = this;
        var viewXml = "<View><Query><Where><Eq><FieldRef Name='Enabled'/><Value Type='Boolean'>1</Value></Eq></Where></Query></View>";

        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('FeatureToggle')/GetItems",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': viewXml
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        }).then(function (resp) {
            $rootScope.featureToggles = {};
            angular.forEach(resp.data.value, function (value, key) {
                $rootScope.featureToggles[value.Title] = true;
            });
            $rootScope.$broadcast('featureTogglesLoaded');
        });
    }

    var flatCountries = null;

    var requestCountryInfo = function () {

        var self = this;
        var viewXml = "<View><Query><Where><Eq><FieldRef Name='locale' /><Value Type='Text'>" + getUserLocale() + "</Value></Eq></Where></Query></View>";

        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('Countries')/GetItems(query=@v1)?@v1={\"ViewXml\":\"<View><Query></Query></View>\"}&$select=Country,UNGeoSchemeRegion,UNGeoSchemeSubRegion,ISOCountryCode,DisplayCountry,DisplayRegion,DisplaySubregion,DisplayOrder",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': viewXml
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        }).then(function (resp) {

            //Container array to hold the key value pair
            $rootScope.topCountryArray = {};
            $rootScope.CountryCodeArray = [];

            //array to contain the JSON structure
            $rootScope.jsonCountryArray = [];

            // for (var key in obj) {
            angular.forEach(resp.data.value, function (value, key) {
                var regionLabel = value.UNGeoSchemeRegion.Label;
                var subregionLabel = value.UNGeoSchemeSubRegion.Label;
                var countryLabel = value.Country.Label;
                var countryCodeLabel = value.ISOCountryCode;
                var displayCountry = value.displayCountry;
                var displayRegion = value.DisplayRegion;
                var displaySubRegion = value.DisplaySubregion;
                var displayOrder = value.DisplayOrder;
                var displayCountry = value.DisplayCountry;

                ////////////////create the region section ///////////////////////////////////
                //// the first time through the flat list, no region, subregion or country will exist in the hierarchal array
                var topArrayRegion = $rootScope.topCountryArray[regionLabel];
                if (topArrayRegion == undefined) {
                    //////create a new Region object //////
                    var newRegion = new Object();
                    newRegion.region = regionLabel;
                    newRegion.displayName = displayRegion;
                    newRegion.subregions = [];

                    topArrayRegion = new Object();
                    topArrayRegion.subregions = {};
                    topArrayRegion.regionObject = newRegion;
                    $rootScope.topCountryArray[regionLabel] = topArrayRegion;
                    $rootScope.jsonCountryArray.push(newRegion);

                }
                ////////////////end create the region section ///////////////////////////////////

                ////////////create the subregion section /////////////////////////////////////
                var topArraySubRegion = topArrayRegion.subregions[subregionLabel];
                if (topArraySubRegion == undefined) {

                    var newSubRegion = new Object();
                    newSubRegion.name = subregionLabel;
                    newSubRegion.displayName = displaySubRegion;
                    newSubRegion.countries = [];

                    topArraySubRegion = new Object();
                    topArraySubRegion.subregionObject = newSubRegion;
                    topArrayRegion.subregions[subregionLabel] = topArraySubRegion;

                    topArrayRegion.regionObject.subregions.push(newSubRegion);

                }

                //////////// end create the subregion section /////////////////////////////////////

                /////////////// create the country section ////////////////////////////////////////
                var newCountry = new Object();
                newCountry.name = countryLabel;
                newCountry.countryCode = countryCodeLabel;

                ///////////////// Create the flat list of country codes /////////////////////
                /// used to supply a key value pair of Country name and ISO codes for use across the site
                $rootScope.CountryCodeArray.push(newCountry);

                ///////////////// End the flat list of country codes /////////////////////


                //set the order of the country to be displayed in
                //this will assist when displaying translated countries that don't use
                //the Latin alphabet or if we want to elevate or descend a country
                //for example; place USA first in a American locale
                var parsedDisplay = parseInt(displayOrder, 10);
                if (isNaN(parsedDisplay)) {
                    newCountry.displayOrder = 0;
                } else {
                    newCountry.displayOrder = parsedDisplay;
                }

                newCountry.displayName = displayCountry;

                topArrayRegion.subregions[subregionLabel].countries = {};
                topArrayRegion.subregions[subregionLabel].countries[countryLabel] = new Object();
                topArrayRegion.subregions[subregionLabel].countries[countryLabel].countryObject = newCountry;

                topArraySubRegion.subregionObject.countries.push(newCountry);



                /////////////// end create the country section ////////////////////////////////////////

            });
            /////////////////// Finish of creation of first section /////////////////////////////
        });
    };

    var requestRegions = function (selectedCountry) {
        var self = this;
        var viewXml = "<View><Query><Where><Eq><FieldRef Name='locale' /><Value Type='Text'>" + getUserLocale() + "</Value></Eq></Where></Query></View>";
        if (!!selectedCountry) {
            viewXml = "<View><Query><Where><Eq><FieldRef Name=\"Country\" /><Value Type=\"TaxonomyFieldType\">" + selectedCountry + "</Value></Eq></Where></Query></View>";
        }


        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('Countries')/getitems?$select=Country,DisplayCountry,HQTitle,HQAddress,IsCorporateRegionHQ,IsGlobalHQ,ISOCountryCode,DisplayRegion,DisplayOrder",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': viewXml
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        }).then(function (resp) {

            flatCountries = resp.data.value;
            var regions = [];
            var regionNames = [];
            angular.forEach(resp.data.value, function (v) {
                var obj = {
                    name: v.Country.Label,
                    guid: v.Country.TermGuid
                };
                var hqObj = {
                    HQAddress: v.HQAddress,
                    HQTitle: v.HQTitle,
                    globalHQ: v.IsGlobalHQ,
                    corpHQ: v.IsCorporateRegionHQ,
                    Country: v.Country.Label
                };

                if (regionNames.indexOf(v.DisplayRegion) === -1) {
                    regions.push({
                        name: v.DisplayRegion,
                        countries: [obj],
                        hqInfo: v.HQAddress ? [hqObj] : [],
                        selected: v.Country.Label === self.getUserCountry(),
                        countryCode: v.ISOCountryCode
                    });
                    regionNames.push(v.DisplayRegion);

                } else {

                    var result = regions.filter(function (obj) {
                        return obj.name == v.DisplayRegion;
                    });
                    result[0].countries.push(obj);
                    if (v.Country.Label === self.getUserCountry()) {
                        result[0].selected = true;
                    }
                    if (v.HQAddress) {
                        result[0].hqInfo.push(hqObj);
                    }
                }

            });

            angular.forEach(regions, function (v) {
                v.countries = v.countries.sort(function (a, b) {
                    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                });
            });

            return regions.sort(function (a, b) {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            });
        });
    };

    var getFlatCountries = function () {
        return flatCountries;
    };

    var getLanguages = function () {

        return [{
            code: "af-ZA",
            label: "Afrikaans"
        }, {
            code: "sq-AL",
            label: "Albanian"
        }, {
            code: "gsw-FR",
            label: "Alsatian"
        }, {
            code: "am-ET",
            label: "Amharic"
        }, {
            code: "ar-DZ",
            label: "Arabic (Algeria)"
        }, {
            code: "ar-BH",
            label: "Arabic (Bahrain)"
        }, {
            code: "ar-EG",
            label: "Arabic (Egypt)"
        }, {
            code: "ar-IQ",
            label: "Arabic (Iraq)"
        }, {
            code: "ar-JO",
            label: "Arabic (Jordan)"
        }, {
            code: "ar-KW",
            label: "Arabic (Kuwait)"
        }, {
            code: "ar-LB",
            label: "Arabic (Lebanon)"
        }, {
            code: "ar-LY",
            label: "Arabic (Libya)"
        }, {
            code: "ar-MA",
            label: "Arabic (Morocco)"
        }, {
            code: "ar-OM",
            label: "Arabic (Oman)"
        }, {
            code: "ar-QA",
            label: "Arabic (Qatar)"
        }, {
            code: "ar-SA",
            label: "Arabic (Saudi Arabia)"
        }, {
            code: "ar-SY",
            label: "Arabic (Syria)"
        }, {
            code: "ar-TN",
            label: "Arabic (Tunisia)"
        }, {
            code: "ar-AE",
            label: "Arabic (U.A.E.)"
        }, {
            code: "ar-YE",
            label: "Arabic (Yemen)"
        }, {
            code: "hy-AM",
            label: "Armenian"
        }, {
            code: "as-IN",
            label: "Assamese"
        }, {
            code: "az-Cyrl-AZ",
            label: "Azerbaijani (Cyrillic)"
        }, {
            code: "az-Latn-AZ",
            label: "Azerbaijani (Latin)"
        }, {
            code: "bn-BD",
            label: "Bangla (Bangladesh)"
        }, {
            code: "ba-RU",
            label: "Bashkir"
        }, {
            code: "eu-ES",
            label: "Basque"
        }, {
            code: "be-BY",
            label: "Belarusian"
        }, {
            code: "bn-IN",
            label: "Bengali (India)"
        }, {
            code: "bs-Cyrl-BA",
            label: "Bosnian (Cyrillic)"
        }, {
            code: "bs-Latn-BA",
            label: "Bosnian (Latin)"
        }, {
            code: "br-FR",
            label: "Breton"
        }, {
            code: "bg-BG",
            label: "Bulgarian"
        }, {
            code: "ca-ES",
            label: "Catalan"
        }, {
            code: "zh-HK",
            label: "Chinese (Hong Kong S.A.R.)"
        }, {
            code: "zh-MO",
            label: "Chinese (Macao S.A.R.)"
        }, {
            code: "zh-CN",
            label: "Chinese (PRC)"
        }, {
            code: "zh-SG",
            label: "Chinese (Singapore)"
        }, {
            code: "zh-TW",
            label: "Chinese (Taiwan)"
        }, {
            code: "co-FR",
            label: "Corsican"
        }, {
            code: "hr-BA",
            label: "Croatian (Bosnia and Herzegovina)"
        }, {
            code: "hr-HR",
            label: "Croatian (Croatia)"
        }, {
            code: "cs-CZ",
            label: "Czech"
        }, {
            code: "da-DK",
            label: "Danish"
        }, {
            code: "prs-AF",
            label: "Dari"
        }, {
            code: "dv-MV",
            label: "Divehi"
        }, {
            code: "nl-BE",
            label: "Dutch (Belgium)"
        }, {
            code: "nl-NL",
            label: "Dutch (Netherlands)"
        }, {
            code: "en-AU",
            label: "English (Australia)"
        }, {
            code: "en-BZ",
            label: "English (Belize)"
        }, {
            code: "en-CA",
            label: "English (Canada)"
        }, {
            code: "en-029",
            label: "English (Caribbean)"
        }, {
            code: "en-IN",
            label: "English (India)"
        }, {
            code: "en-IE",
            label: "English (Ireland)"
        }, {
            code: "en-JM",
            label: "English (Jamaica)"
        }, {
            code: "en-MY",
            label: "English (Malaysia)"
        }, {
            code: "en-NZ",
            label: "English (New Zealand)"
        }, {
            code: "en-PH",
            label: "English (Philippines)"
        }, {
            code: "en-SG",
            label: "English (Singapore)"
        }, {
            code: "en-ZA",
            label: "English (South Africa)"
        }, {
            code: "en-TT",
            label: "English (Trinidad and Tobago)"
        }, {
            code: "en-GB",
            label: "English (United Kingdom)"
        }, {
            code: "en-US",
            label: "English (United States)"
        }, {
            code: "en-ZW",
            label: "English (Zimbabwe)"
        }, {
            code: "et-EE",
            label: "Estonian"
        }, {
            code: "fo-FO",
            label: "Faroese"
        }, {
            code: "fil-PH",
            label: "Filipino"
        }, {
            code: "fi-FI",
            label: "Finnish"
        }, {
            code: "fr-BE",
            label: "French (Belgium)"
        }, {
            code: "fr-CA",
            label: "French (Canada)"
        }, {
            code: "fr-FR",
            label: "French (France)"
        }, {
            code: "fr-LU",
            label: "French (Luxembourg)"
        }, {
            code: "fr-MC",
            label: "French (Monaco)"
        }, {
            code: "fr-CH",
            label: "French (Switzerland)"
        }, {
            code: "fy-NL",
            label: "Frisian"
        }, {
            code: "gl-ES",
            label: "Galician"
        }, {
            code: "ka-GE",
            label: "Georgian"
        }, {
            code: "de-AT",
            label: "German (Austria)"
        }, {
            code: "de-DE",
            label: "German (Germany)"
        }, {
            code: "de-LI",
            label: "German (Liechtenstein)"
        }, {
            code: "de-LU",
            label: "German (Luxembourg)"
        }, {
            code: "de-CH",
            label: "German (Switzerland)"
        }, {
            code: "el-GR",
            label: "Greek"
        }, {
            code: "kl-GL",
            label: "Greenlandic"
        }, {
            code: "gu-IN",
            label: "Gujarati"
        }, {
            code: "ha-Latn-NG",
            label: "Hausa (Latin)"
        }, {
            code: "he-IL",
            label: "Hebrew"
        }, {
            code: "hi-IN",
            label: "Hindi"
        }, {
            code: "hu-HU",
            label: "Hungarian"
        }, {
            code: "is-IS",
            label: "Icelandic"
        }, {
            code: "ig-NG",
            label: "Igbo"
        }, {
            code: "smn-FI",
            label: "Inari Sami"
        }, {
            code: "id-ID",
            label: "Indonesian"
        }, {
            code: "iu-Latn-CA",
            label: "Inuktitut (Latin)"
        }, {
            code: "iu-Cans-CA",
            label: "Inuktitut (Syllabics)"
        }, {
            code: "ga-IE",
            label: "Irish"
        }, {
            code: "it-IT",
            label: "Italian (Italy)"
        }, {
            code: "it-CH",
            label: "Italian (Switzerland)"
        }, {
            code: "ja-JP",
            label: "Japanese"
        }, {
            code: "qut-GT",
            label: "K'iche'"
        }, {
            code: "kn-IN",
            label: "Kannada"
        }, {
            code: "kk-KZ",
            label: "Kazakh"
        }, {
            code: "km-KH",
            label: "Khmer"
        }, {
            code: "rw-RW",
            label: "Kinyarwanda"
        }, {
            code: "sw-KE",
            label: "Kiswahili"
        }, {
            code: "kok-IN",
            label: "Konkani"
        }, {
            code: "ko-KR",
            label: "Korean"
        }, {
            code: "ky-KG",
            label: "Kyrgyz"
        }, {
            code: "lo-LA",
            label: "Lao"
        }, {
            code: "lv-LV",
            label: "Latvian"
        }, {
            code: "lt-LT",
            label: "Lithuanian"
        }, {
            code: "dsb-DE",
            label: "Lower Sorbian"
        }, {
            code: "smj-NO",
            label: "Lule Sami (Norway)"
        }, {
            code: "smj-SE",
            label: "Lule Sami (Sweden)"
        }, {
            code: "lb-LU",
            label: "Luxembourgish"
        }, {
            code: "mk-MK",
            label: "Macedonian (FYROM)"
        }, {
            code: "ms-BN",
            label: "Malay (Brunei Darussalam)"
        }, {
            code: "ms-MY",
            label: "Malay (Malaysia)"
        }, {
            code: "ml-IN",
            label: "Malayalam"
        }, {
            code: "mt-MT",
            label: "Maltese"
        }, {
            code: "mi-NZ",
            label: "Maori"
        }, {
            code: "arn-CL",
            label: "Mapudungun"
        }, {
            code: "mr-IN",
            label: "Marathi"
        }, {
            code: "moh-CA",
            label: "Mohawk"
        }, {
            code: "mn-MN",
            label: "Mongolian (Cyrillic)"
        }, {
            code: "mn-Mong-CN",
            label: "Mongolian (Traditional Mongolian)"
        }, {
            code: "ne-NP",
            label: "Nepali"
        }, {
            code: "se-FI",
            label: "Northern Sami (Finland)"
        }, {
            code: "se-NO",
            label: "Northern Sami (Norway)"
        }, {
            code: "se-SE",
            label: "Northern Sami (Sweden)"
        }, {
            code: "nb-NO",
            label: "Norwegian (Bokmal)"
        }, {
            code: "nn-NO",
            label: "Norwegian (Nynorsk)"
        }, {
            code: "oc-FR",
            label: "Occitan"
        }, {
            code: "or-IN",
            label: "Odia"
        }, {
            code: "ps-AF",
            label: "Pashto"
        }, {
            code: "fa-IR",
            label: "Persian"
        }, {
            code: "pl-PL",
            label: "Polish"
        }, {
            code: "pt-BR",
            label: "Portuguese (Brazil)"
        }, {
            code: "pt-PT",
            label: "Portuguese (Portugal)"
        }, {
            code: "pa-IN",
            label: "Punjabi"
        }, {
            code: "quz-BO",
            label: "Quechua (Bolivia)"
        }, {
            code: "quz-EC",
            label: "Quechua (Ecuador)"
        }, {
            code: "quz-PE",
            label: "Quechua (Peru)"
        }, {
            code: "ro-RO",
            label: "Romanian"
        }, {
            code: "rm-CH",
            label: "Romansh"
        }, {
            code: "ru-RU",
            label: "Russian"
        }, {
            code: "sah-RU",
            label: "Sakha"
        }, {
            code: "sa-IN",
            label: "Sanskrit"
        }, {
            code: "gd-GB",
            label: "Scottish Gaelic"
        }, {
            code: "sr-Cyrl-BA",
            label: "Serbian (Cyrillic, Bosnia and Herzegovina)"
        }, {
            code: "sr-Cyrl-ME",
            label: "Serbian (Cyrillic, Montenegro)"
        }, {
            code: "sr-Cyrl-RS",
            label: "Serbian (Cyrillic, Serbia)"
        }, {
            code: "sr-Latn-BA",
            label: "Serbian (Latin, Bosnia and Herzegovina)"
        }, {
            code: "sr-Latn-ME",
            label: "Serbian (Latin, Montenegro)"
        }, {
            code: "sr-Latn-RS",
            label: "Serbian (Latin, Serbia)"
        }, {
            code: "nso-ZA",
            label: "Sesotho sa Leboa"
        }, {
            code: "tn-ZA",
            label: "Setswana"
        }, {
            code: "si-LK",
            label: "Sinhala"
        }, {
            code: "sms-FI",
            label: "Skolt Sami"
        }, {
            code: "sk-SK",
            label: "Slovak"
        }, {
            code: "sl-SI",
            label: "Slovenian"
        }, {
            code: "sma-NO",
            label: "Southern Sami (Norway)"
        }, {
            code: "sma-SE",
            label: "Southern Sami (Sweden)"
        }, {
            code: "es-AR",
            label: "Spanish (Argentina)"
        }, {
            code: "es-BO",
            label: "Spanish (Bolivia)"
        }, {
            code: "es-CL",
            label: "Spanish (Chile)"
        }, {
            code: "es-CO",
            label: "Spanish (Colombia)"
        }, {
            code: "es-CR",
            label: "Spanish (Costa Rica)"
        }, {
            code: "es-DO",
            label: "Spanish (Dominican Republic)"
        }, {
            code: "es-EC",
            label: "Spanish (Ecuador)"
        }, {
            code: "es-SV",
            label: "Spanish (El Salvador)"
        }, {
            code: "es-GT",
            label: "Spanish (Guatemala)"
        }, {
            code: "es-HN",
            label: "Spanish (Honduras)"
        }, {
            code: "es-MX",
            label: "Spanish (Mexico)"
        }, {
            code: "es-NI",
            label: "Spanish (Nicaragua)"
        }, {
            code: "es-PA",
            label: "Spanish (Panama)"
        }, {
            code: "es-PY",
            label: "Spanish (Paraguay)"
        }, {
            code: "es-PE",
            label: "Spanish (Peru)"
        }, {
            code: "es-PR",
            label: "Spanish (Puerto Rico)"
        }, {
            code: "es-ES",
            label: "Spanish (Spain)"
        }, {
            code: "es-ES",
            label: "Spanish (Spain, Traditional)"
        }, {
            code: "es-US",
            label: "Spanish (United States)"
        }, {
            code: "es-UY",
            label: "Spanish (Uruguay)"
        }, {
            code: "es-VE",
            label: "Spanish (Venezuela)"
        }, {
            code: "sv-FI",
            label: "Swedish (Finland)"
        }, {
            code: "sv-SE",
            label: "Swedish (Sweden)"
        }, {
            code: "syr-SY",
            label: "Syriac"
        }, {
            code: "tg-Cyrl-TJ",
            label: "Tajik"
        }, {
            code: "tzm-Latn-DZ",
            label: "Tamazight (Latin)"
        }, {
            code: "ta-IN",
            label: "Tamil"
        }, {
            code: "tt-RU",
            label: "Tatar"
        }, {
            code: "te-IN",
            label: "Telugu"
        }, {
            code: "th-TH",
            label: "Thai"
        }, {
            code: "bo-CN",
            label: "Tibetan"
        }, {
            code: "tr-TR",
            label: "Turkish"
        }, {
            code: "tk-TM",
            label: "Turkmen"
        }, {
            code: "uk-UA",
            label: "Ukrainian"
        }, {
            code: "hsb-DE",
            label: "Upper Sorbian"
        }, {
            code: "ur-PK",
            label: "Urdu"
        }, {
            code: "ug-CN",
            label: "Uyghur"
        }, {
            code: "uz-Cyrl-UZ",
            label: "Uzbek (Cyrillic)"
        }, {
            code: "uz-Latn-UZ",
            label: "Uzbek (Latin)"
        }, {
            code: "vi-VN",
            label: "Vietnamese"
        }, {
            code: "cy-GB",
            label: "Welsh"
        }, {
            code: "wo-SN",
            label: "Wolof"
        }, {
            code: "ii-CN",
            label: "Yi"
        }, {
            code: "yo-NG",
            label: "Yoruba"
        }, {
            code: "xh-ZA",
            label: "isiXhosa"
        }, {
            code: "zu-ZA",
            label: "isiZulu"
        }];
    };

    var getAreasOfInterest = function () {
        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('DisplayAreasOfInterest')/getitems?$select=Segment,PreferredName",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': "<View><Query><Where><Eq><FieldRef Name='locale' /><Value Type='Text'>" + getUserLocale() + "</Value></Eq></Where></Query></View>"
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        });
    };

    var getProducts = function () {
        var country = this.getUserCountry();
        return $http({

            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('DisplayProducts')/getitems?$select=uniqueProductId,Product,Title,ProductGroup,locale",

            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': "<View><Query><Where><And><Eq><FieldRef Name='locale' /><Value Type='Text'>" + getUserLocale() + "</Value></Eq><Eq><FieldRef Name=\"BCTCountries\" /><Value Type=\"TaxonomyFieldTypeMulti\">" + country + "</Value></Eq></And></Where></Query></View>"
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        });
    };

    var saveContactUs = function (obj) {
        return $http({
            method: "POST",
            url: "/_vti_bin/BCT.Ext.Services/FormToListService.svc/SaveItem/v2?list=~sitecollection/lists/contactus",
            data: JSON.stringify(obj)
        });
    };

    var switchLanguageCSS = function (langKey) {
        document.getElementById("CssRegistration_language").setAttribute("href", "/_catalogs/masterpage/dotcom/css/languages/" + langKey + ".css");
    };

    var getPressReleases = function (limit) {
        limit = limit || "";
        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('Pages')/getitems?$select=Title,ArticleStartDate,EncodedAbsUrl",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': "<View Scope=\"RecursiveAll\"><Query><Where><Or><Eq><FieldRef Name=\"ContentType\"/><Value Type=\"Computed\">BCT Press Release</Value></Eq><Eq><FieldRef Name=\"BCTCategory\" /><Value Type=\"TaxonomyFieldType\">Press Releases</Value></Eq></Or></Where><OrderBy><FieldRef Name=\"ArticleStartDate\" Ascending=\"FALSE\"/></OrderBy></Query><RowLimit>" + limit + "</RowLimit></View>"
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        });
    };

    var getEvents = function () {

        //https://social.msdn.microsoft.com/Forums/office/en-US/069f84d8-d7e9-4484-a220-8c29e8538a9a/how-to-work-with-caml-dates-using-javascript-in-sharepoint?forum=sharepointdevelopment
        var todayDate = new Date();
        var todayDate = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + (todayDate.getDate()) + 'T00:00:01Z';

        return $http({
            url: _spPageContextInfo.siteAbsoluteUrl + "/_api/web/lists/GetByTitle('EventCalendar')/getItems(query=@v1)?@v1={'ViewXml':'<View><Query></Query></View>'}&$select=Title,CustomerSegment,Website,Location,EventPublishDate,EventExpireDate,EventStartDate,EventEndDate",
            method: "POST",
            data: JSON.stringify({
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },

                    'ViewXml': "<View Scope=\"RecursiveAll\"><Query><Where><And> <Or> <IsNull> <FieldRef Name='EventPublishDate' /> </IsNull> <Leq> <FieldRef Name='EventPublishDate' /> <Value IncludeTimeValue='TRUE' Type='DateTime'>" + todayDate + "</Value> </Leq> </Or> <Or> <IsNull> <FieldRef Name='EventExpireDate' /> </IsNull> <Geq> <FieldRef Name='EventExpireDate' /> <Value IncludeTimeValue='TRUE' Type='DateTime'>" + todayDate + "</Value> </Geq> </Or></And></Where><OrderBy><FieldRef Name='EventStartDate' Ascending='False' /></OrderBy></Query></View>"
                }
            }),
            headers: {
                "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                "Accept": "application/json;odata=nometadata",
                "Content-Type": "application/json; odata=verbose"
            }
        });
    };

    var setUrl = function () {
        $window.location.href = "/_layouts/15/Authenticate.aspx?Source=" + $window.location.href;
    };

    var setJapan = function() {
        this.setUserLocale('ja');
        this.setUserCountry('japan');
    }

    var setUserGlobalAndLanguage = function (language) {
        hideGlobalMessage();
        //set the users country to Global
        document.cookie = "userCountry=global; max-age=31556926; path=/;domain=" + document.domain;

        setUserLocale(language);
        if (!$rootScope.isAnonymous) {
            $http({
                url: _spPageContextInfo.siteAbsoluteUrl + "/_vti_bin/BCT.Ext.Services/MyProfileService.svc/SaveMyProfile/v2",
                method: "POST",
                headers: {
                    "X-RequestDigest": $document[0].getElementById("__REQUESTDIGEST").value,
                    "Content-Type": "application/json; odata=nometadata"
                },
                data: JSON.stringify({
                    Country: "Global"
                })
            }).then(function () {
                //redirect to language page for user
                //go to chinese for zh, japanese for ja and default for all else
                if (language !== "en-us") {
                    $window.location.href = ($window.location.origin || '') + "/" + language;
                } else {
                    $window.location.href = ($window.location.origin || '/');
                }
            });
        } else {
            var path = $window.location.pathname;
            
            var newPath = '/';
            var langSegment = '';
            if (language !== 'en-us') {
                langSegment = '/' + language;
            }

            angular.forEach(path.split('/'), function(value) {
                if (value && value != 'ja' && value != 'zh') {
                    newPath += value + '/';
                }                    
            });

            var targetUrl = ($window.location.origin || '') + langSegment + newPath + $window.location.search + $window.location.hash;
            targetUrl = targetUrl.replace(/\/$/, ''); // replace trailing slash

            if (targetUrl !== $window.location.href) {
                 $window.location.href = targetUrl;
            }
            else {
                $rootScope.isCountryPickerShowing = false;
                this.getNav('global');
            }
        }

    };

    return {
        getUrlVars: getUrlVars,
        checkForParams: checkForParams,
        getSupportTopics: getSupportTopics,
        isAnonymous: isAnonymous,
        hasCountry: hasCountry,
        globalMessageFeatureDisabled: globalMessageFeatureDisabled,
        getUserCountry: getUserCountry,
        setUserCountry: setUserCountry,
        getUserLocale: getUserLocale,
        setUserLocale: setUserLocale,
        isGlobalContent: isGlobalContent,
        requestUserProfile: requestUserProfile,
        getSuggestions: getSuggestions,
        getUserProfile: getUserProfile,
        setUserProfile: setUserProfile,
        getNav: getNav,
        hideGlobalMessage: hideGlobalMessage,
        forceGlobalMessage: forceGlobalMessage,
        requestRegions: requestRegions,
        requestCountryInfo: requestCountryInfo,
        requestFeatureToggles: requestFeatureToggles,
        getFlatCountries: getFlatCountries,
        getLanguages: getLanguages,
        getAreasOfInterest: getAreasOfInterest,
        getProducts: getProducts,
        saveContactUs: saveContactUs,
        switchLanguageCSS: switchLanguageCSS,
        getPressReleases: getPressReleases,
        getEvents: getEvents,
        setUrl: setUrl,
        setUserGlobalAndLanguage: setUserGlobalAndLanguage,
        setJapan: setJapan
    };
}]);

/*smooth scroll*/
app.service("smoothScroll", ["$window", "$timeout", function ($window, $timeout) {
    var easeInOutCubic = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    var position = function (start, end, elapsed, duration) {
        if (elapsed > duration) {
            return end;
        }
        return start + (end - start) * easeInOutCubic(elapsed / duration);
    };
    var smoothScroll = function (el, duration, callback) {
        duration = duration || 500;
        var start = $window.pageYOffset;
        var end = parseInt(el);
        var clock = Date.now();
        var requestAnimationFrame = $window.requestAnimationFrame ||
            $window.mozRequestAnimationFrame || $window.webkitRequestAnimationFrame ||
            function (fn) {
                $timeout(fn, 15);
            };
        var step = function () {
            var elapsed = Date.now() - clock;
            $window.scroll(0, position(start, end, elapsed, duration));
            if (duration > elapsed) {
                requestAnimationFrame(step);
            } else {
                if (typeof callback === "function") {
                    callback(el);
                }
            }
        };
        step();
    };
    return smoothScroll;
}]);

app.service("arrayService", ["$sce", function ($sce) {

    var stripDuplicates = function (inputArray, field) {
        var termArray = [];
        var outputArray = [];
        for (var i = 0; i < inputArray.length; i++) {
            if (termArray.indexOf(inputArray[i][field]) === -1) {
                termArray.push(inputArray[i][field]);
                var o = {
                    value: inputArray[i][field]
                };
                o[field] = inputArray[i][field];
                outputArray.push(o);
            }
        }
        return outputArray;
    };

    var getAddressesFromList = function (arr) {

        var addressArr = [];
        var regionArr = [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].Address) {
                addressArr.push(arr[i]);
                regionArr.push({
                    Region: arr[i].Region,
                    value: arr[i].Region
                });
            }
        }
        return {
            addresses: addressArr,
            regions: regionArr
        };
    };

    var renderHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    //function to convert hex color to rgba
    var hexToRgb = function (hex, opacity) {
        opacity = opacity || 1;
        hex = hex.substring(1);
        var colorNumber = parseInt(hex, 16);
        return "rgba(" + [(colorNumber = parseInt(hex, 16)) >> 16 & 255, colorNumber >> 8 & 255, colorNumber & 255, opacity].join() + ")";
    };

    var getMonth = function (date) {
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[date.getMonth()];
    };

    return {
        stripDuplicates: stripDuplicates,
        renderHtml: renderHtml,
        getAddressesFromList: getAddressesFromList,
        hexToRgb: hexToRgb,
        getMonth: getMonth
    };
}]);

//filter to create startFrom point in ng-repeat
app.filter("startFrom", ["", function () {
    return function (input, start) {
        if (input) {
            start = +start; //parse to int
            return input.slice(start);
        }
        return [];
    };
}]);

app.filter("stripSpecialChars", function () {
    return function (input) {
        return input.replace(/(<([^>]+)>)/ig, "").replace(/[^\w\s]/gi, "");
    };
});

app.filter("pluralize", function () {
    return function (i) {
        return i + (i == 1 ? " result" : " results");
    };
});

app.filter("articleFilter", function () {
    return function (input, val) {
        var arr = [];
        for (var i = 0; i < input.length; i++) {
            if (input[i].header.search(new RegExp(val, 'gi')) > -1 || input[i].content.search(new RegExp(val, 'gi')) > -1) {
                arr.push(input[i]);
            }
        }
        return val ? arr : input;
    };
});

app.filter('highlight', ["$sce", function ($sce) {
    return function (text, phrase) {
        if (phrase) {
            text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
                '<span class="highlighted">$1</span>');
        }

        return $sce.trustAsHtml(text);
    };
}]);