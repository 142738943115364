if (typeof (DotCom) === 'undefined') {
    (function () {
        var DotCom = function () {
            return new DotCom.fn.init();
        };
        DotCom.fn = DotCom.prototype = {
            init: function () {
                return this;
            }
        };
        window.DotCom = DotCom();
    })();
}


(function () {
    DotCom.App = {
        Utilities: {
            officeExtensions: ['doc', 'docx', 'docm', 'dotx', 'dotm',
                'xlsx', 'xls', 'xlsm', 'xltx', 'xltm', 'xlam',
                'ppt', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm'
            ],
            isOfficeDocument: function (fullFilePath) {
                var pos = fullFilePath.lastIndexOf('.') + 1;
                if (!!pos) {
                    var ext = fullFilePath.slice(pos);
                    for (var i = 0; i < this.officeExtensions.length; i++) {
                        if (this.officeExtensions[i].toLowerCase() === ext.toLowerCase()) {
                            return true;
                        }
                    }
                }
                return false;
            },
            openDocument: function (fullFilePath) {
                ga('send', 'event', 'resource', 'open', fullFilePath); // ga() is a method of Google Analytics
                window.open(DotCom.App.Utilities.getOpenLink(fullFilePath));
            },
            getOpenLink: function (fullFilePath) {
                if (this.isOfficeDocument(fullFilePath)) {
                    var fileToGet = encodeURI(fullFilePath);
                    return _spPageContextInfo.siteAbsoluteUrl + '/_layouts/15/BCT.Ext.Web/Download.aspx?path=' + fileToGet;
                } else {
                    return fullFilePath;
                }
            }
        },
        UI: {
            Navigation: {
                stageSPHeader: function (_userCanManageWeb) {
                    if (_userCanManageWeb) {
                        document.getElementById("Site-Header").className = "main-header";
                        document.getElementById("Site-Header").className += " main-header-collapse";
                        document.getElementById("Site-Header").className += " editmode";
                        document.getElementById("s4-workspace").className += " editmode";
                        var rollovers = document.getElementsByClassName('rollover'),
                            i = rollovers.length;
                        while (i--) {
                            rollovers[i].className = "rollover";
                            rollovers[i].className += " rollover-collapse";
                        }
                    }
                }
            }
        }
    };
})();