var app = app;
var angular = angular;

app.factory('regionalizedcountries', ["$http", "$rootScope", "sharePointService", function($http, $rootScope, sharePointService) {
    var countries = null;

    return function() {
      if (countries) {
        // If we've already asked for this data once,
        // return the promise that already exists.
        return countries;
      } else {
        //promise = $http.get('jsonData/regionalizedCountries.json');
        countries = sharePointService.requestCountryInfo();
        return countries;
      }
    };
  }]);



