var app = app;
var angular = angular;

app.controller("ctrlCountryPickerDropDown",  [ '$scope', '$translate', '$rootScope', 'sharePointService', 
    function($scope, $translate, $rootScope, sharePointService) {
    
    $scope.regionalizedcountries = $rootScope.jsonCountryArray;
    $scope.selectedCountry = sharePointService.getUserCountry();
    $scope.userLocale = sharePointService.getUserLocale();


    $scope.templateUrl = null;
    
    $scope.getTemplateUrl = function() {
        return $scope.templateUrl;
    }
    
    $scope.setUserGlobalAndLanguage = function(language) {
        sharePointService.setUserGlobalAndLanguage(language);
    };

    $scope.setJapan = function() {
        sharePointService.setJapan();
    };

    $scope.setUserCountry = function (country) {
        $scope.selectedCountry = country;
        sharePointService.setUserCountry(country);
        sharePointService.requestCountryInfo();
    };

    $scope.selectSubRegion = function(event) {
        var currTarget = event.currentTarget.parentElement;
        var toggleOff = currTarget.classList.contains('selected');
        var subRegions = document.querySelectorAll('.subRegionParent');
        for (var i = 0; i < subRegions.length; i++){
            subRegions[i].classList.remove('selected');
        }
        if(!toggleOff){
            currTarget.classList.add('selected');
        }
    };
    angular.element(document).ready(function () {
        var selectedCountry = document.querySelector(".countryItem > .selected");
        if(selectedCountry){
            selectedCountry.closest(".subRegionParent").classList.add("selected");
        }
    });
}]);  

app.controller("SelectLanguage", ["$scope", "$translate", "sharePointService", function($scope, $translate, sharePointService){
    $scope.changeLanguage = function (key) {
        sharePointService.setUserLocale(key);
    };
}]);

app.controller("ElqFormCtrl", ["$scope", "$rootScope", "$document", "sharePointService", "$translate", function ($scope, $rootScope, $document, sharePointService, $translate) {

    _elqQ = _elqQ || [];
    _elqQ.push(['elqSetSiteId', "1232678232"]);
    _elqQ.push(['elqTrackPageView']);
    var firstLookup = true;

    SetElqContent = function () {
        if (firstLookup) {

            //primary
            _elqQ.push(['elqDataLookup', escape("3855cde2-084d-42ec-b4c4-3dfea869d828"), '<C_EmailAddress>' + GetElqContentPersonalizationValue("V_ElqEmailAddress") + '</C_EmailAddress>']);
            firstLookup = false;
        } else {
            var allElements = document.getElementsByTagName('ng-form')[0].getElementsByTagName('*');
            for (var i = 0; i < allElements.length; i++) {
                if (allElements[i].getAttribute('ng-model') && GetElqContentPersonalizationValue(allElements[i].id)) {

                    var ngModel = angular.element(allElements[i]).data('$ngModelController');
                    ngModel.$setViewValue(GetElqContentPersonalizationValue(allElements[i].id));
                    ngModel.$render();

                }
            }
        }
    };


    function elqVisitorTrackingOptIn() {
        _elqQ.push(['elqOptIn']);
    }

    function elqVisitorTrackingOptOut() {
        _elqQ.push(['elqOptOut']);
    }

    function elqCreateOptInBanner() {
        if (navigator.appVersion.indexOf('MSIE') != -1) {
            var css = '#ELQ-Banner{position:absolute;z-index:1000;width:560px;}.elqOptInBanner {position: absolute; top: 0px; left: 0px; width:100%; border:solid 1px #c0c0c0; background-color:#e1e1e1; font-size:11px; fontfamily:verdana; color:#000; padding:5px;}.elqOptInBannerText{float: left; textalign:left; width:96%;} .elqButton {font-size:11px; color:#000; padding:3px;}.elqClose{float:right; font-size:14px; font-weight:bold;cursor:pointer; padding-right:15px;} ';
        } else {
            css = '#ELQ-Banner{position:absolute;z-index:1000;width:560px;}.elqOptInBanner {position: fixed; top: 0px; left: 0px; width:100%; border:solid 1px #c0c0c0;background-color:#e1e1e1; font-size:11px; fontfamily:verdana; color:#000; padding:5px;}.elqOptInBannerText {float: left;textalign:left; width:96%;}.elqButton {font-size:11px; color:#000; padding:3px;}.elqClose {float:right; font-size:14px; font-weight:bold; cursor:pointer; padding-right:15px;} ';
        }
        var style = document.createElement('STYLE');
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(style);
        var div = document.createElement('div');
        div.setAttribute('id', 'elqOptInBannerDiv');
        div.className = 'elqOptInBanner';
        div.innerHTML = '<div class="elqOptInBannerText" >This website would like to use cookies to store information on your computer which will help to customize your interactions with us and provide you with a better browsing experience. You may delete and block cookies from this site, but parts of the site may not function as a result. More information about cookies and your choices can be found in our Privacy Policy. To accept cookies from this site, please click the \'I Accept\' button below.&nbsp;&nbsp;&nbsp;<input type="button" id="elqOptInButton" value="I Accept" onClick="elqVisitorTrackingOptIn();document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';" class="elqButton" />&nbsp;&nbsp;<input type="button" id="elqOptOutButton" value="I Decline" onClick="elqVisitorTrackingOptOut();document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';" class="elqButton" /></div><div class="elqClose" onclick="document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';">x</div>';
        document.getElementById("ELQ-Banner").appendChild(div);
    }

    //opt in
    //_elqQ.push(['elqTrackPageViewDisplayOptInBannerByCountry']);

    //visitor
    _elqQ.push(['elqDataLookup', escape("922f119a0b4144978e8e0080db6e7b3e"), '']);

    //get flat country list
    sharePointService.requestCountryInfo().then(function () {
        $scope.countryList = [];
        angular.forEach(sharePointService.getFlatCountries(), function (v) {
            $scope.countryList.push({
                name: v.Country.Label
            });
        });


    });

    $scope.clearForm = function () {
        $scope.userInfo = null;
    };


    $scope.cellTypes = [];
    if ($rootScope.i18nLoaded) {
        $translate(['CELLTYPE_CANCER_CELL', 'CELLTYPE_LYMPHOCYTES',
            'CELLTYPE_MESENCHYMAL', 'CELLTYPE_FIBROBLASTS',
            'CELLTYPE_MONOCYTES', 'CELLTYPE_INDUCED_PLURIPOTENT'
        ]).then(function (translations) {
            $scope.cellTypes = [{
                title: translations.CELLTYPE_CANCER_CELL
            }, {
                title: translations.CELLTYPE_LYMPHOCYTES
            }, {
                title: translations.CELLTYPE_MESENCHYMAL
            }, {
                title: translations.CELLTYPE_FIBROBLASTS
            }, {
                title: translations.CELLTYPE_MONOCYTES
            }, {
                title: translations.CELLTYPE_INDUCED_PLURIPOTENT
            }];
        });
    }
    else {
        $scope.$on('translation-loaded', function() {
            $translate(['CELLTYPE_CANCER_CELL', 'CELLTYPE_LYMPHOCYTES',
                'CELLTYPE_MESENCHYMAL', 'CELLTYPE_FIBROBLASTS',
                'CELLTYPE_MONOCYTES', 'CELLTYPE_INDUCED_PLURIPOTENT'
            ]).then(function (translations) {
                $scope.cellTypes = [{
                    title: translations.CELLTYPE_CANCER_CELL
                }, {
                    title: translations.CELLTYPE_LYMPHOCYTES
                }, {
                    title: translations.CELLTYPE_MESENCHYMAL
                }, {
                    title: translations.CELLTYPE_FIBROBLASTS
                }, {
                    title: translations.CELLTYPE_MONOCYTES
                }, {
                    title: translations.CELLTYPE_INDUCED_PLURIPOTENT
                }];
            });
        });
    }    

    $scope.challenges = [];
    if ($rootScope.i18nLoaded) {
        $translate(['CHALLENGES_SCALE', 'CHALLENGES_YIELD',
            'CHALLENGES_COST', 'CHALLENGES_TIME',
            'CHALLENGES_CONSISTENCY', 'CHALLENGES_CONTAMINATION',
            'CHALLENGES_RESOURCES', 'CHALLENGES_LABOR',
            'CHALLENGES_NONE'
        ]).then(function (translations) {
            $scope.challenges = [{
                title: translations.CHALLENGES_SCALE
            }, {
                title: translations.CHALLENGES_YIELD
            }, {
                title: translations.CHALLENGES_COST
            }, {
                title: translations.CHALLENGES_TIME
            }, {
                title: translations.CHALLENGES_CONSISTENCY
            }, {
                title: translations.CHALLENGES_CONTAMINATION
            }, {
                title: translations.CHALLENGES_RESOURCES
            }, {
                title: translations.CHALLENGES_LABOR
            }, {
                title: translations.CHALLENGES_NONE
            }];
        });
    }
    else {
        $scope.$on('translation-loaded', function() {
            $translate(['CHALLENGES_SCALE', 'CHALLENGES_YIELD',
                'CHALLENGES_COST', 'CHALLENGES_TIME',
                'CHALLENGES_CONSISTENCY', 'CHALLENGES_CONTAMINATION',
                'CHALLENGES_RESOURCES', 'CHALLENGES_LABOR',
                'CHALLENGES_NONE'
            ]).then(function (translations) {
                $scope.challenges = [{
                    title: translations.CHALLENGES_SCALE
                }, {
                    title: translations.CHALLENGES_YIELD
                }, {
                    title: translations.CHALLENGES_COST
                }, {
                    title: translations.CHALLENGES_TIME
                }, {
                    title: translations.CHALLENGES_CONSISTENCY
                }, {
                    title: translations.CHALLENGES_CONTAMINATION
                }, {
                    title: translations.CHALLENGES_RESOURCES
                }, {
                    title: translations.CHALLENGES_LABOR
                }, {
                    title: translations.CHALLENGES_NONE
                }];
            });
        });
    }

    $scope.titleRole = [];
    $translate(['FORMCHOICE_TITLEROLE_PHYSICIAN', 'FORMCHOICE_TITLEROLE_NURSE', 
            'FORMCHOICE_TITLEROLE_NURSE_MANAGER', 'FORMCHOICE_TITLEROLE_MEDICAL_DIRECTOR', 
            'FORMCHOICE_TITLEROLE_APHERESIS_PROVIDER', 'FORMCHOICE_TITLEROLE_UNIT_MANAGER', 
            'FORMCHOICE_TITLEROLE_APHERESIS_OPERATOR']).then(function (translations) {
        $scope.titleRole = [{
            title: translations.FORMCHOICE_TITLEROLE_PHYSICIAN
        }, {
            title: translations.FORMCHOICE_TITLEROLE_NURSE
        }, {
            title: translations.FORMCHOICE_TITLEROLE_NURSE_MANAGER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_MEDICAL_DIRECTOR
        }, {
            title: translations.FORMCHOICE_TITLEROLE_APHERESIS_PROVIDER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_UNIT_MANAGER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_APHERESIS_OPERATOR
        }];
    });    

    //Added by Mata
    $scope.plasmaExchangeObstacles = [{
        title: "Treatment Costs"
    }, {
        title: "Vascular Access"
    }, {
        title: "Access to Equipment"
    }, {
        title: "Adverse Events"
    }, {
        title: "Convenience of procedures"
    }, {
        title: "Procedure length"
    }];


    //Added by Mata
    $scope.neuroPatientOpportunities = [{
        title: "Time of patient’s response to treatment"
    }, {
        title: "Time it takes to get an accurate diagnosis for a patient"
    }, {
        title: "Time it takes to determine which treatment option will stabilize the patient in the shortest amount of time"
    }, {
        title: "Minimize the time it takes to determine when the next treatment for the should begin"
    }];

    //Added by Mata
    $scope.howManyPerYear = [{
        title: "Less than 5"
    }, {
        title: "5-10"
    }, {
        title: "11-15"
    }, {
        title: "16-20"
    }, {
        title: "21-25"
    }, {
        title: "Over 25"
    }];


    //Added by Mata
    $scope.stepsToAutomate = [{
        title: "Cell source material collection"
    }, {
        title: "Cell isolation and selection"
    }, {
        title: "Cell expansion"
    }, {
        title: "Cell modification (including gene, viral)"
    }, {
        title: "Cell washing and concentration"
    }, {
        title: "Cell formulation (fill and finish)"
    }];

    //Added by Mata
    $scope.processingCellTypes = [{
        title: "Adherent cells"
    }, {
        title: "Suspension cells"
    }, {
        title: "Specific cell types"
    }];

    //Added by Mata
    $scope.applicationCellTypes = [{
        title: "Early bench research"
    }, {
        title: "Translational/academic research"
    }, {
        title: "Clinical trial(s) – Phase I or II"
    }, {
        title: "Clinical trial(s) – Phase III"
    }, {
        title: "Approved cell therapy/commercialization of a product"
    }, {
        title: "Hospital exemption"
    }];

    //Added by Mata
    $scope.processTechNeeds = [{
        title: "Ability to scale cell yields up or down to meet needs"
    }, {
        title: "Ability to expand/process a variety of cell types"
    }, {
        title: "Meet GMP environment requirements"
    }, {
        title: "Closed system"
    }, {
        title: "Simplicity in operation"
    }, {
        title: "Potential for seamless interconnectivity/integration with other equipment in the process"
    }, {
        title: "Flexibility to customize equipment protocols"
    }, {
        title: "Real-time analytical evaluation (enumeration of cells, phenotype analysis) via separate device/add-on"
    }];



    $scope.conversations = [];
    if ($rootScope.i18nLoaded) {
        $translate(['CONVERSATIONS_YES_EMAIL', 'CONVERSATIONS_YES_PHONE',
                'CONVERSATIONS_NO']).then(function (translations) {
            $scope.conversations = [translations.CONVERSATIONS_YES_EMAIL, translations.CONVERSATIONS_YES_PHONE, translations.CONVERSATIONS_NO]
        });
    }
    else {
        $scope.$on('translation-loaded', function() {
            $translate(['CONVERSATIONS_YES_EMAIL', 'CONVERSATIONS_YES_PHONE',
                    'CONVERSATIONS_NO']).then(function (translations) {
                $scope.conversations = [translations.CONVERSATIONS_YES_EMAIL, translations.CONVERSATIONS_YES_PHONE, translations.CONVERSATIONS_NO]
            });
        });
    }
    

    //function to save contact us form
    $scope.submitSuccess = null;
    $scope.submitForm = function () {
        var formId = document.getElementsByTagName('ng-form')[0].getAttribute("data-form-id");
        var f = document.createElement("form");
        f.setAttribute('method', "post");
        f.setAttribute('name', formId);
        f.setAttribute('action', "https://s1232678232.t.eloqua.com/e/f2");

        var i1 = document.createElement("input");
        i1.setAttribute("value", formId);
        i1.setAttribute("name", "elqFormName");
        f.appendChild(i1);

        var i2 = document.createElement("input");
        i2.setAttribute("value", "1232678232");
        i2.setAttribute("name", "elqSiteId");
        f.appendChild(i2);

        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail" || formId == "CTT-Material-DigitalDownload-Quantum" || formId == "CTT-Material-DirectMail-Quantum" || formId == "CTT-Material-DigitalDownload-Elutra") {  
            var i3 = document.getElementById("MaterialRequest");
            i3.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
            f.appendChild(i3);
        }


        var i4 = document.getElementById("prospectSourceFirstTouch");
        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail" || formId == "CTT-Material-DigitalDownload-Quantum" || formId == "CTT-Material-DirectMail-Quantum" || formId == "CTT-Material-DigitalDownload-Elutra") {
            i4.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
        } else if (formId == "CTT-General-Contact") {
            i4.setAttribute("value", "CTT-General-Contact");
        } else if (formId == "CTT-Ask-The-Expert") {
            i4.setAttribute("value", "CTT-Ask-The-Expert");
        } else if (formId == "TS-VA-Training") {
            i4.setAttribute("value", "TS-VA-Training");
        }
        f.appendChild(i4);


        var i5 = document.getElementById("prospectSourceMostRecentTouch1");
        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail" || formId == "CTT-Material-DigitalDownload-Quantum" || formId == "CTT-Material-DirectMail-Quantum" || formId == "CTT-Material-DigitalDownload-Elutra") {
            i5.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
        } else if (formId == "CTT-General-Contact") {
            i5.setAttribute("value", "CTT-General-Contact");
        } else if (formId == "CTT-Ask-The-Expert") {
            i5.setAttribute("value", "CTT-Ask-The-Expert");
        } else if(formId == "TS-VA-Training") {
            i5.setAttribute("value", "TS-VA-Training");
        }
        f.appendChild(i5);

        if(document.getElementById("sfID")){
            var i6 = document.getElementById("sfID");
    
            if(formId == "Quantum-Materials-DL-Form32-Update" || formId == "Quantum-Materials-DL-Form8-Update" || formId == "Quantum-Materials-DL-Form24-Update"){
                if(sharePointService.getUrlVars()["gclid"] != undefined){
                    i6.setAttribute("value", "7010V000002aiMj");
                }
                else{
                    i6.setAttribute("value", "7010V000001ysb7");
                }
            }
            else if(formId == "CTT-SCDIIB-Contact"){
                if(sharePointService.getUrlVars()["gclid"] != undefined){
                    //if it's a Google adwords ID
                    i6.setAttribute("value", "7010V000001ysbR");
                }
                else{
                    //else
                    i6.setAttribute("value", "7010V000001z3yA");
                }
            }

            
            f.appendChild(i6);
        }
        

        if (formId == "CTT-Material-DigitalDownload-Quantum" || formId == "CTT-Material-DirectMail-Quantum" || formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail" || formId == "CTT-Material-DigitalDownload-Elutra") {
            var i7 = document.createElement("input");
            i7.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["email_id"]));
            i7.setAttribute("name", "emailID1");
            i7.setAttribute("id", "C_Email_ID1");
            f.appendChild(i7);
        }

        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail" || formId == "CTT-Material-DigitalDownload-Quantum" || formId == "CTT-Material-DirectMail-Quantum" || formId == "CTT-Material-DigitalDownload-Elutra") {
            var urlString = "https://terumobct.com/Pages/CTT-Material-Confirm.aspx?ref=" + decodeURIComponent(sharePointService.getUrlVars()["materials"]);
            var i8 = document.getElementById("thankYouURL");
            i8.setAttribute("value", urlString);
            f.appendChild(i8);
        }



        var allElements = document.getElementsByTagName('ng-form')[0].getElementsByTagName('*');
        for (var i = 0; i < allElements.length; i++) {
            if (allElements[i].getAttribute('ng-model')) {
                if (allElements[i].tagName === "SLIDING-SELECT" || allElements[i].tagName === "SELECT") {
                    var input = document.createElement("input");
                    input.setAttribute("value", angular.element(allElements[i]).data('$ngModelController').$viewValue);
                    input.setAttribute("id", allElements[i].id);
                    input.setAttribute("name", angular.element(allElements[i]).attr("name"));
                    angular.element(f).append(input);
                } else {
                    angular.element(f).append(angular.copy(allElements[i]));
                }
            }
        }

        document.getElementsByTagName('body')[0].appendChild(f).submit();
    };
}]);

app.controller("ELQCookieController", ["$scope", "$document", "sharePointService", function ($scope, $document, sharePointService) {
    $scope.showCookieBanner = true;
    $scope.cookiesDeclined = false;

    //Added by Mata
    $scope.alertClicked = false;
    //

    var CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear( ) +10);

    $scope.CookieDateFuture = CookieDate.toUTCString() ;


    //Added by Mata - checks URL for NZ/AU case
    //Ex URL: www.terumobct.com/?location=New Zealand (anything w/ a '?location=Australia' etc)
    $scope.selectedCountry = sharePointService.getUserCountry();
    var countryDisplay = document.getElementsByClassName("countryCode")[0];
    // var url_string = window.location.href;
    // var url = new URL(url_string);
    // var formSegPre = url.search;
    // var formSeg = formSegPre.replace('?location=','');
    function getQueryString() {
        var key = false, res = {}, itm = null;
        // get the query string without the ?
        var qs = location.search.substring(1);
        // check for the key as an argument
        if (arguments.length > 0 && arguments[0].length > 1)
          key = arguments[0];
        // make a regex pattern to grab key/value
        var pattern = /([^&=]+)=([^&]*)/g;
        // loop the items in the query string, either
        // find a match to the argument, or build an object
        // with key/value pairs
        while (itm = pattern.exec(qs)) {
          if (key !== false && decodeURIComponent(itm[1]) === key)
            return decodeURIComponent(itm[2]);
          else if (key === false)
            res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }

        return key === false ? res : null;
}


    var formSeg = getQueryString('location');
    console.log(formSeg);
    

    if($scope.selectedCountry != "New Zealand" || $scope.selectedCountry != "Australia"){

        if(formSeg == "Australia"){
            sharePointService.setUserCountry("Australia");
        }
        else if(formSeg == "New Zealand" || formSeg == "New%20Zealand"){
            sharePointService.setUserCountry("New Zealand");
        }

    }





    //used to decide when to hide the cookie banner
    var name, index, cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        index = cookies[i].indexOf('=');
        if (index > 0 && cookies[i].length > index + 1) {
            name = cookies[i].substr(0, index).trim();
            //Added by Mata
            if (name == 'aunzAlertClicked') {
                $scope.alertClicked = true;
            }
            //
            if (name == 'ELQOPTCHOICE') {
                // if this cookie exists, user has made choice already
                $scope.showCookieBanner = false;
                //
            }
        }
    }


    //Added by Mata

    if ($scope.alertClicked === false){
        // New Zealand and Australia users must acknowledge they are healthcare professionals - requested by regulatory
        if ($scope.selectedCountry === "New Zealand" || $scope.selectedCountry === "Australia") {
        
            alert("Content presented is intended for Healthcare Professionals Only.");
            $scope.alertClicked = true;
            if ($scope.showCookieBanner === false) {
                document.cookie = "aunzAlertClicked=1; expires ='"+ $scope.CookieDateFuture + "';";
            }
        }
    }
    //





    _elqQ = _elqQ || [];
    _elqQ.push(['elqSetSiteId', '1232678232']);

   //If you are implementing first party cookies for use on a secured SSL microsite, then you will need the tracking domain to also be secured. Please refer to the KB
    //document How to Install an SSL Certificate For My Microsite (Doc ID 1946150.1) for further instructions. If you are using the tracking domain for use on a secure SSL
    //microsite, work with your IT team to ensure your tracking domain has a CNAME record pointing towards Eloqua in the following syntax: s[SiteID].hs.eloqua.com.

    //Once support has enabled this functionality, ensure you add in the following line of code to your externally tracked
    //_elqQ.push(['elqUseFirstPartyCookie', 'app.comdev13.terumobct.com']);

    _elqQ.push(['elqTrackPageViewDisplayOptInBannerForAll']);
  
    $scope.elqVisitorTrackingOptIn = function () {
        _elqQ.push(['elqOptIn']);
        $scope.showCookieBanner = false;
        document.cookie = "ELQOPTCHOICE=In; expires ='"+ $scope.CookieDateFuture + "';";
        //Added by Mata 
        if ($scope.alertClicked) {
            document.cookie = "aunzAlertClicked=1; expires ='"+ $scope.CookieDateFuture + "';";
        }
        //
    };

    $scope.elqVisitorTrackingOptOut = function () {
        _elqQ.push(['elqOptOut']);
        $scope.cookiesDeclined = true;
        document.cookie = "ELQOPTCHOICE=Out; expires ='"+ $scope.CookieDateFuture + "';";
    };

    $scope.closeCookieBanner = function(){
        $scope.showCookieBanner=false;
    };

}]);

app.controller("CCFormCtrl", ["$scope", "$rootScope", "$document", "sharePointService", "$translate", function ($scope, $rootScope, $document, sharePointService, $translate) {

    _elqQ = _elqQ || [];
    _elqQ.push(['elqSetSiteId', "1232678232"]);
    _elqQ.push(['elqTrackPageView']);
    var firstLookup = true;

    SetElqContent = function () {
        if (firstLookup) {

            //primary
            _elqQ.push(['elqDataLookup', escape("3855cde2-084d-42ec-b4c4-3dfea869d828"), '<C_EmailAddress>' + GetElqContentPersonalizationValue("V_ElqEmailAddress") + '</C_EmailAddress>']);
            firstLookup = false;
        } else {
            var allElements = document.getElementsByTagName('ng-form')[0].getElementsByTagName('*');
            for (var i = 0; i < allElements.length; i++) {
                if (allElements[i].getAttribute('ng-model') && GetElqContentPersonalizationValue(allElements[i].id)) {

                    var ngModel = angular.element(allElements[i]).data('$ngModelController');
                    ngModel.$setViewValue(GetElqContentPersonalizationValue(allElements[i].id));
                    ngModel.$render();

                }
            }
        }
    };


    function elqVisitorTrackingOptIn() {
        _elqQ.push(['elqOptIn']);
    }

    function elqVisitorTrackingOptOut() {
        _elqQ.push(['elqOptOut']);
    }

    function elqCreateOptInBanner() {
        if (navigator.appVersion.indexOf('MSIE') != -1) {
            var css = '#ELQ-Banner{position:absolute;z-index:1000;width:560px;}.elqOptInBanner {position: absolute; top: 0px; left: 0px; width:100%; border:solid 1px #c0c0c0; background-color:#e1e1e1; font-size:11px; fontfamily:verdana; color:#000; padding:5px;}.elqOptInBannerText{float: left; textalign:left; width:96%;} .elqButton {font-size:11px; color:#000; padding:3px;}.elqClose{float:right; font-size:14px; font-weight:bold;cursor:pointer; padding-right:15px;} ';
        } else {
            css = '#ELQ-Banner{position:absolute;z-index:1000;width:560px;}.elqOptInBanner {position: fixed; top: 0px; left: 0px; width:100%; border:solid 1px #c0c0c0;background-color:#e1e1e1; font-size:11px; fontfamily:verdana; color:#000; padding:5px;}.elqOptInBannerText {float: left;textalign:left; width:96%;}.elqButton {font-size:11px; color:#000; padding:3px;}.elqClose {float:right; font-size:14px; font-weight:bold; cursor:pointer; padding-right:15px;} ';
        }
        var style = document.createElement('STYLE');
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(style);
        var div = document.createElement('div');
        div.setAttribute('id', 'elqOptInBannerDiv');
        div.className = 'elqOptInBanner';
        div.innerHTML = '<div class="elqOptInBannerText" >This website would like to use cookies to store information on your computer which will help to customize your interactions with us and provide you with a better browsing experience. You may delete and block cookies from this site, but parts of the site may not function as a result. More information about cookies and your choices can be found in our Privacy Policy. To accept cookies from this site, please click the \'I Accept\' button below.&nbsp;&nbsp;&nbsp;<input type="button" id="elqOptInButton" value="I Accept" onClick="elqVisitorTrackingOptIn();document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';" class="elqButton" />&nbsp;&nbsp;<input type="button" id="elqOptOutButton" value="I Decline" onClick="elqVisitorTrackingOptOut();document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';" class="elqButton" /></div><div class="elqClose" onclick="document.getElementById(\'elqOptInBannerDiv\').style.display = \'none\';">x</div>';
        document.getElementById("ELQ-Banner").appendChild(div);
    }

    //opt in
    //_elqQ.push(['elqTrackPageViewDisplayOptInBannerByCountry']);

    //visitor
    _elqQ.push(['elqDataLookup', escape("922f119a0b4144978e8e0080db6e7b3e"), '']);

    //get flat country list
    sharePointService.requestCountryInfo().then(function () {
        $scope.countryList = [];
        angular.forEach(sharePointService.getFlatCountries(), function (v) {
            $scope.countryList.push({
                name: v.Country.Label
            });
        });


    });

    $scope.clearForm = function () {
        $scope.userInfo = null;
    };

    $scope.cellTypes = [];
    $translate(['CELLTYPE_CANCER_CELL', 'CELLTYPE_LYMPHOCYTES',
        'CELLTYPE_MESENCHYMAL', 'CELLTYPE_FIBROBLASTS',
        'CELLTYPE_MONOCYTES', 'CELLTYPE_INDUCED_PLURIPOTENT'
    ]).then(function (translations) {
        $scope.cellTypes = [
            {
                title: translations.CELLTYPE_CANCER_CELL
            },
            {
                title: translations.CELLTYPE_LYMPHOCYTES
            }, {
                title: translations.CELLTYPE_MESENCHYMAL
            }, {
                title: translations.CELLTYPE_FIBROBLASTS
            }, {
                title: translations.CELLTYPE_MONOCYTES
            }, {
                title: translations.CELLTYPE_INDUCED_PLURIPOTENT
            }
        ]
    });

    $scope.challenges = [];
    $translate(['CHALLENGES_SCALE', 'CHALLENGES_YIELD',
        'CHALLENGES_COST', 'CHALLENGES_TIME',
        'CHALLENGES_CONSISTENCY', 'CHALLENGES_CONTAMINATION',
        'CHALLENGES_RESOURCES', 'CHALLENGES_LABOR',
        'CHALLENGES_NONE'
    ]).then(function (translations) {
    $scope.challenges = [{
        title: translations.CHALLENGES_SCALE
    }, {
        title: translations.CHALLENGES_YIELD
    }, {
        title: translations.CHALLENGES_COST
    }, {
        title: translations.CHALLENGES_TIME
    }, {
        title: translations.CHALLENGES_CONSISTENCY
    }, {
        title: translations.CHALLENGES_CONTAMINATION
    }, {
        title: translations.CHALLENGES_RESOURCES
    }, {
        title: translations.CHALLENGES_LABOR
    }, {
        title: translations.CHALLENGES_NONE
    }];
    });

    $scope.titleRole = [];
    $translate(['FORMCHOICE_TITLEROLE_PHYSICIAN', 'FORMCHOICE_TITLEROLE_NURSE', 
            'FORMCHOICE_TITLEROLE_NURSE_MANAGER', 'FORMCHOICE_TITLEROLE_MEDICAL_DIRECTOR', 
            'FORMCHOICE_TITLEROLE_APHERESIS_PROVIDER', 'FORMCHOICE_TITLEROLE_UNIT_MANAGER', 
            'FORMCHOICE_TITLEROLE_APHERESIS_OPERATOR']).then(function (translations) {
        $scope.titleRole = [{
            title: translations.FORMCHOICE_TITLEROLE_PHYSICIAN
        }, {
            title: translations.FORMCHOICE_TITLEROLE_NURSE
        }, {
            title: translations.FORMCHOICE_TITLEROLE_NURSE_MANAGER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_MEDICAL_DIRECTOR
        }, {
            title: translations.FORMCHOICE_TITLEROLE_APHERESIS_PROVIDER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_UNIT_MANAGER
        }, {
            title: translations.FORMCHOICE_TITLEROLE_APHERESIS_OPERATOR
        }];
    }); 



        //Added by Mata
        $scope.plasmaExchangeObstacles = [{
            title: "Treatment Costs"
        }, {
            title: "Vascular Access"
        }, {
            title: "Access to Equipment"
        }, {
            title: "Adverse Events"
        }, {
            title: "Convenience of procedures"
        }, {
            title: "Procedure length"
        }];
    
    
        //Added by Mata
        $scope.neuroPatientOpportunities = [{
            title: "Time of patient’s response to treatment"
        }, {
            title: "Time it takes to get an accurate diagnosis for a patient"
        }, {
            title: "Time it takes to determine which treatment option will stabilize the patient in the shortest amount of time"
        }, {
            title: "Minimize the time it takes to determine when the next treatment for the should begin"
        }];
    
        //Added by Mata
        $scope.howManyPerYear = [{
            title: "Less than 5"
        }, {
            title: "5-10"
        }, {
            title: "11-15"
        }, {
            title: "16-20"
        }, {
            title: "21-25"
        }, {
            title: "Over 25"
        }];
    
    
        //Added by Mata
        $scope.stepsToAutomate = [{
            title: "Cell source material collection"
        }, {
            title: "Cell isolation and selection"
        }, {
            title: "Cell expansion"
        }, {
            title: "Cell modification (including gene, viral)"
        }, {
            title: "Cell washing and concentration"
        }, {
            title: "Cell formulation (fill and finish)"
        }];
    
        //Added by Mata
        $scope.processingCellTypes = [{
            title: "Adherent cells"
        }, {
            title: "Suspension cells"
        }, {
            title: "Specific cell types"
        }];
    
        //Added by Mata
        $scope.applicationCellTypes = [{
            title: "Early bench research"
        }, {
            title: "Translational/academic research"
        }, {
            title: "Clinical trial(s) – Phase I or II"
        }, {
            title: "Clinical trial(s) – Phase III"
        }, {
            title: "Approved cell therapy/commercialization of a product"
        }, {
            title: "Hospital exemption"
        }];
    
        //Added by Mata
        $scope.processTechNeeds = [{
            title: "Ability to scale cell yields up or down to meet needs"
        }, {
            title: "Ability to expand/process a variety of cell types"
        }, {
            title: "Meet GMP environment requirements"
        }, {
            title: "Closed system"
        }, {
            title: "Simplicity in operation"
        }, {
            title: "Potential for seamless interconnectivity/integration with other equipment in the process"
        }, {
            title: "Flexibility to customize equipment protocols"
        }, {
            title: "Real-time analytical evaluation (enumeration of cells, phenotype analysis) via separate device/add-on"
        }];









    $scope.conversations = [];
    if ($rootScope.i18nLoaded) {
        $translate(['CONVERSATIONS_YES_EMAIL', 'CONVERSATIONS_YES_PHONE',
                'CONVERSATIONS_NO']).then(function (translations) {
            $scope.conversations = [translations.CONVERSATIONS_YES_EMAIL, translations.CONVERSATIONS_YES_PHONE, translations.CONVERSATIONS_NO]
        });
    }
    else {
        $scope.$on('translation-loaded', function() {
            $translate(['CONVERSATIONS_YES_EMAIL', 'CONVERSATIONS_YES_PHONE',
                    'CONVERSATIONS_NO']).then(function (translations) {
                $scope.conversations = [translations.CONVERSATIONS_YES_EMAIL, translations.CONVERSATIONS_YES_PHONE, translations.CONVERSATIONS_NO]
            });
        });
    }

    //function to save contact us form
    $scope.submitSuccess = null;
    $scope.submitForm = function () {
        var formId = sharePointService.getUrlVars()["form_id"];
        var f = document.createElement("form");
        f.setAttribute('method', "post");
        f.setAttribute('name', formId);
        f.setAttribute('action', "https://s1232678232.t.eloqua.com/e/f2");

        var i1 = document.createElement("input");
        i1.setAttribute("value", formId);
        i1.setAttribute("name", "elqFormName");
        f.appendChild(i1);

        var i2 = document.createElement("input");
        i2.setAttribute("value", "1232678232");
        i2.setAttribute("name", "elqSiteId");
        f.appendChild(i2);

        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail") {
            var i3 = document.getElementById("MaterialRequest");
            i3.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
            f.appendChild(i3);
        }

        var i4 = document.getElementById("prospectSourceFirstTouch");
        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail") {
            i4.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
        } else if (formId == "CTT-General-Contact") {
            i4.setAttribute("value", "CTT-General-Contact");
        } else if (formId == "CTT-Ask-The-Expert") {
            i4.setAttribute("value", "CTT-Ask-The-Expert");
        } else if (formId == "TS-VA-Training") {
            i4.setAttribute("value", "TS-VA-Training");
        }
        f.appendChild(i4);


        var i5 = document.getElementById("prospectSourceMostRecentTouch1");
        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail") {
            i5.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["materials"]));
        } else if (formId == "CTT-General-Contact") {
            i5.setAttribute("value", "CTT-General-Contact");
        } else if (formId == "CTT-Ask-The-Expert") {
            i5.setAttribute("value", "CTT-Ask-The-Expert");
        } else if(formId == "TS-VA-Training") {
            i5.setAttribute("value", "TS-VA-Training");
        }
        f.appendChild(i5);


        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail") {
            var i6 = document.createElement("input");
            i6.setAttribute("value", decodeURIComponent(sharePointService.getUrlVars()["email_id"]));
            i6.setAttribute("name", "emailID1");
            i6.setAttribute("id", "C_Email_ID1");
            f.appendChild(i6);
        }


        if (formId == "CTT-Material-DigitalDownload" || formId == "CTT-Material-DirectMail") {
            var urlString = "https://terumobct.com/Pages/CTT-Material-Confirm.aspx?ref=" + decodeURIComponent(sharePointService.getUrlVars()["materials"]);
            var i7 = document.getElementById("thankYouURL");
            i7.setAttribute("value", urlString);
            f.appendChild(i7);
        }



        var allElements = document.getElementsByTagName('ng-form')[0].getElementsByTagName('*');
        for (var i = 0; i < allElements.length; i++) {
            if (allElements[i].getAttribute('ng-model')) {
                if (allElements[i].tagName === "SLIDING-SELECT" || allElements[i].tagName === "SELECT") {
                    var input = document.createElement("input");
                    input.setAttribute("value", angular.element(allElements[i]).data('$ngModelController').$viewValue);
                    input.setAttribute("id", allElements[i].id);
                    input.setAttribute("name", angular.element(allElements[i]).attr("name"));
                    angular.element(f).append(input);
                } else {
                    angular.element(f).append(angular.copy(allElements[i]));
                }
            }
        }

        document.getElementsByTagName('body')[0].appendChild(f).submit();
    };
}]);

app.controller("ContactUsCtrl", ["$scope", "$rootScope", "sharePointService", "arrayService", function ($scope, $rootScope, sharePointService, arrayService) {

    //show the address on page load (URL var)
    $scope.addressShow = sharePointService.getUrlVars()["showAddresses"] == "true" ? true : false;

    //function to render and return raw html
    $scope.arrayService = arrayService;

    //get flat country list
    sharePointService.requestRegions().then(function (resp) {
        $scope.countryList = [];
        $scope.regionList = [];

        var selectedIndex = 0;
        angular.forEach(resp, function (v) {
            if (v.hqInfo.length) {
                $scope.regionList.push(v);
                angular.forEach(v.hqInfo, function (v2) {
                    if (v2.globalHQ) {
                        $scope.globalHQ = v2;
                    }
                });
            }
        });

        angular.forEach($scope.regionList, function (v, i) {
            if (v.selected) {
                selectedIndex = i;
            }
        });

        $scope.selectedRegion = $scope.regionList[selectedIndex];

        angular.forEach(sharePointService.getFlatCountries(), function (v) {
            $scope.countryList.push({
                name: v.Country.Label,
                guid: v.Country.TermGuid,
                display: v.DisplayCountry,
                displayOrder: v.DisplayOrder
            });

        });

        //get country name if user has selected one
        $scope.countryName = sharePointService.getUserCountry();

        $scope.searchFilters = {
            addressFilter: "",
            searchInput: ""
        };

        //set the guid
        $scope.setGuid($scope.countryName);

    });

    //get list of products
    $scope.getProducts = function () {
        sharePointService.getProducts().then(function (resp) {
            $scope.products = [];
            angular.forEach(resp.data.value, function (v) {
                $scope.products.push({
                    productLabel: v.Title,
                    productShortName: v.uniqueProductId,
                    productKey: v.Product.Label + "|" + v.Product.TermGuid,
                    productGroup: v.ProductGroup
                });
            });

            var product = decodeURIComponent(sharePointService.getUrlVars()["product"]);
            if (topic && product) {
                angular.forEach($scope.products, function (v) {
                    if (v.productShortName === product) {
                        $scope.userInfo.Product = v.productKey;
                    }
                });
            }
        });
    };

    $scope.getProducts();

    //get areas of interest
    var aoi = decodeURIComponent(sharePointService.getUrlVars()["aoi"]);
    sharePointService.getAreasOfInterest().then(function (resp) {
        $scope.aoi = [];
        angular.forEach(resp.data.value, function (v) {
            var area = {
                name: v.PreferredName,
                value: v.Segment.Label
            }
            $scope.aoi.push(area);
            if (v.Segment.Label === aoi) {
                $scope.userInfo.Segment = area;
            }
        });
    });

    sharePointService.getSupportTopics().then(function (resp) {
        $scope.helpOptions = {
            selected: null,
            data: resp.data
        };
    });

    

    //model for data to submit to contact us service
    $scope.userInfo = {
        Country: "",
        Topic: "",
        SupportCategory: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Company: "",
        Product: "",
        Segment: "",
        CatalogNumber: "",
        LotNumber: "",
        ProductSerialNumber: "",
        Message: "",
        ReceiveEmails: false
    };

    //function to set GUID from
    $scope.setGuid = function (c) {
        angular.forEach($scope.countryList, function (v) {
            if (c === v.name) {
                $scope.userInfo.Country = v.name + "|" + v.guid;
            }
        });
        $scope.getProducts();
    };

    //function to update support field to show buttons to redirect if needed
    $scope.update = function (h) {

        //reset hidden fields
        $scope.userInfo.Product = "";
        $scope.userInfo.Segment = "";
        $scope.userInfo.CatalogNumber = "";
        $scope.userInfo.LotNumber = "";
        $scope.userInfo.ProductSerialNumber = "";

        //assign index

        angular.forEach($scope.helpOptions.data, function (v, i) {

            if (h === v.value) {
                $scope.helpOptions.selected = i;
            }
        });

        //asign topic name and support category
        $scope.userInfo.Topic = h;
        $scope.userInfo.SupportCategory = $scope.helpOptions.data[$scope.helpOptions.selected].group;

    };

    if ($rootScope.i18nLoaded) {
        //model for updating support topics field and support topics obj
        var topic = decodeURIComponent(sharePointService.getUrlVars()["topic"]);
        if (topic && topic !==undefined && topic !== "undefined") {
            sharePointService.getSupportTopics().then(function (resp) {
                angular.forEach(resp.data, function (v) {
                    if (v.value === topic) {
                        $scope.helpField = v.value;
                        $scope.update($scope.helpField);
                    }
                });
            });        
        }
    }
    else {
        $scope.$on('translation-loaded', function() {
            //model for updating support topics field and support topics obj
            var topic = decodeURIComponent(sharePointService.getUrlVars()["topic"]);
            if (topic && topic !==undefined && topic !== "undefined") {
                sharePointService.getSupportTopics().then(function (resp) {
                    angular.forEach(resp.data, function (v) {
                        if (v.value === topic) {
                            $scope.helpField = v.value;
                            $scope.update($scope.helpField);
                        }
                    });
                });        
            }
        });
    }    

    //function to save contact us form
    $scope.submitSuccess = null;
    $scope.isSubmitting = false;
    $scope.submitForm = function () {
        $scope.isSubmitting = true;
        sharePointService.saveContactUs($scope.userInfo).then(function (resp) {
            $scope.submitSuccess = resp.data.Success;
            $scope.isSubmitting = false;
        }, function () {
            $scope.submitSuccess = false;
            $scope.isSubmitting = false;
        });
    };
}]);

app.controller("QuantumCtrl", ["$scope", "$translate", function ($scope, $translate) {
    $scope.quantumData = [];
    $translate(['QUANTUM_DATA_1', 'QUANTUM_DATA_2',
        'QUANTUM_DATA_3', 'QUANTUM_DATA_4',
        'QUANTUM_DATA_5', 'QUANTUM_DATA_6'
    ]).then(function (translations) {
        $scope.quantumData = [{
            name: translations.QUANTUM_DATA_1,
            index: 0
        }, {
            name: translations.QUANTUM_DATA_2,
            index: 1
        }, {
            name: translations.QUANTUM_DATA_3,
            index: 2
        }, {
            name: translations.QUANTUM_DATA_4,
            index: 3
        }, {
            name: translations.QUANTUM_DATA_5,
            index: 4
        }, {
            name: translations.QUANTUM_DATA_6,
            index: 5
        }, {
            name: translations.QUANTUM_DATA_7,
            index: 6
        }];
    });
}]);

app.controller("QuantumMaterialsCtrl", ["$scope", "$window", "$http", "$sce", "sharePointService", "$translate", function ($scope, $window, $http, $sce, sharePointService, $translate) {
    $scope.userLocale = sharePointService.getUserLocale(); 
    $locale = $scope.userLocale;
    if ($locale === 'en-us') {
        $locale = 'en';
    }
     //Loads JSON into controller -- added by Mata
     $http.get('/_catalogs/masterpage/dotcom/js/quantum-' + $locale + '.json.txt')
     .then(function(res){
        $scope.masterArray = res.data;               
        $scope.radiogroup = {
            selected: 0,
            groups: []
        };
        $translate(['QUANTUM_MATERIALS_GROUP_1', 'QUANTUM_MATERIALS_GROUP_2',
            'QUANTUM_MATERIALS_GROUP_3', 'QUANTUM_MATERIALS_GROUP_4',
            'QUANTUM_MATERIALS_GROUP_5']).then(function (translations) {
                $scope.radiogroup.groups = [translations.QUANTUM_MATERIALS_GROUP_1, translations.QUANTUM_MATERIALS_GROUP_2,
                translations.QUANTUM_MATERIALS_GROUP_3, translations.QUANTUM_MATERIALS_GROUP_4,
                translations.QUANTUM_MATERIALS_GROUP_5]
            });

        $scope.query = "";
        $scope.shownArray = $scope.masterArray;
        $scope.setIndex = function (i) {
            if ($scope.radiogroup.selected === i) {
                return;
            }
            $scope.query = "";
            $scope.radiogroup.selected = i;
            $scope.shownArray = i ? [$scope.masterArray[i - 1]] : $scope.masterArray;
        };
        if (sharePointService.getUrlVars()["selected"]) {
            $scope.setIndex(Number(sharePointService.getUrlVars()["selected"]));
        }
        var masterOpen = false;
        $scope.openAll = function () {
            masterOpen = !masterOpen;
            angular.forEach($scope.shownArray, function (v) {
                angular.forEach(v.segments, function (v2) {
                    angular.forEach(v2.articles, function (v3) {
                        v3.isOpen = masterOpen;
                    });
                });
            });
        };
        //Added by Mata
        $scope.getID = function(){
            var id = window.location.hash.substring(2);
            return id;
        }

        //Added by Mata 
        $scope.showHeaderContent = function() {
            $scope.headerResult = "";
            $scope.contentResult = "";
            angular.forEach($scope.masterArray, function(value, i) {
                var seg = value.segments;
                angular.forEach(seg, function(value, j) {
                    var art = value.articles;
                    angular.forEach(art, function(value, k) {
                        if(value.id == $scope.getID()){
                            $scope.headerResult = $sce.trustAsHtml(value.header);
                            $scope.contentResult = $sce.trustAsHtml(value.content);
                        }
                    });
                });
            }); 
        };
        $scope.showHeaderContent();
        $scope.calculateArticles = function (arr) {
            var count = 0;
            if (arr.segments) {
                for (var i = 0; i < arr.segments.length; i++) {
                    count = count + arr.segments[i].articles.length;
                }
            } else {
                return arr.articles.length;
            }
            return count;
        };
        $scope.resize = function (e) {
            if (e.keyCode == 8 || e.keyCode == 46) {
                angular.element($window).triggerHandler("resize");
            }
        };
     });
}]);

app.controller("ElutraMaterialsCtrl", ["$scope", "$window", "$http", "$sce", "sharePointService", "$translate", function ($scope, $window, $http, $sce, sharePointService, $translate) {

    $scope.userLocale = sharePointService.getUserLocale();

    //Loads JSON into controller -- added by Mata
    $locale = $scope.userLocale;
    if ($locale === 'en-us') {
        $locale = 'en';
    }
    $http.get('/_catalogs/masterpage/dotcom/js/elutra-' + $locale + '.json.txt')
    .then(function(res){

        
        $scope.masterArray = res.data; 

        $scope.radiogroup = {
            selected: 0,
            groups: []
        };

        $translate(['ELUTRA_MATERIALS_GROUP_1', 'ELUTRA_MATERIALS_GROUP_2',
            'ELUTRA_MATERIALS_GROUP_3', 'ELUTRA_MATERIALS_GROUP_4']).then(function (translations) {
                $scope.radiogroup.groups = [translations.ELUTRA_MATERIALS_GROUP_1, translations.ELUTRA_MATERIALS_GROUP_2,
                    translations.ELUTRA_MATERIALS_GROUP_3, translations.ELUTRA_MATERIALS_GROUP_4]
            });

    $scope.query = "";
    $scope.shownArray = $scope.masterArray;
    $scope.setIndex = function (i) {
        if ($scope.radiogroup.selected === i) {
            return;
        }
        $scope.query = "";
        $scope.radiogroup.selected = i;
        $scope.shownArray = i ? [$scope.masterArray[i - 1]] : $scope.masterArray;
    };

    if (sharePointService.getUrlVars()["selected"]) {
        $scope.setIndex(Number(sharePointService.getUrlVars()["selected"]));
    }

    var masterOpen = false;
    $scope.openAll = function () {
        masterOpen = !masterOpen;
        angular.forEach($scope.shownArray, function (v) {
            angular.forEach(v.segments, function (v2) {
                angular.forEach(v2.articles, function (v3) {
                    v3.isOpen = masterOpen;
                });
            });
        });
    };


        //Added by Mata
        $scope.getID = function(){
            var id = window.location.hash.substring(2);
            return id;
        }

        //Added by Mata 
        $scope.showHeaderContent = function() {

            $scope.headerResult = "";
            $scope.contentResult = "";

            angular.forEach($scope.masterArray, function(value, i) {

                var seg = value.segments;
                angular.forEach(seg, function(value, j) {
                    var art = value.articles;
                    angular.forEach(art, function(value, k) {
                        if(value.id == $scope.getID()){
                            $scope.headerResult = $sce.trustAsHtml(value.header);
                            $scope.contentResult = $sce.trustAsHtml(value.content);
                        }
                    });
                });
            }); 
        }

        //Added by Mata 
        $scope.showHeaderContent();


        $scope.calculateArticles = function (arr) {
            var count = 0;
            if (arr.segments) {
                for (var i = 0; i < arr.segments.length; i++) {
                    count = count + arr.segments[i].articles.length;
                }
            } else {
                return arr.articles.length;
            }
            return count;
        };

        $scope.resize = function (e) {
            if (e.keyCode == 8 || e.keyCode == 46) {
                angular.element($window).triggerHandler("resize");
            }
        };

        }
    );

}]);

app.controller("OptiaProtocolCtrl", ["$scope", "$http", "sharePointService", function ($scope, $http, sharePointService) {

    $scope.userLocale = sharePointService.getUserLocale();

    $http.get('/_catalogs/masterpage/dotcom/data/' + $scope.userLocale + '/optiaprotocols.json.txt')
        .then(function (result) {
            $scope.protocols = result.data;
        });
}]);

app.controller("NewsArticleCtrl", ["$scope", "$document", "$interval", "$window", function ($scope, $document, $interval, $window) {
    $scope.print = function () {
        $window.print();
    };
    $document.ready(function () {
        var count = 0;
        var int = $interval(function () {
            angular.element($document[0].getElementsByClassName("fade")[count]).addClass("fade-in");
            count++;
            if (count === $document[0].getElementsByClassName("fade").length) {
                $interval.cancel(int);
            }
        }, 333);
    });
}]);

app.controller("EventCalendarCtrl", ["$scope", "sharePointService", "arrayService", function ($scope, sharePointService, arrayService) {

    //array to hold month names
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    //event array that will be digested into the view
    $scope.events = [];

    //string that will filter business area
    $scope.selectedBA = null;

    //function to filter BA
    $scope.filterBusinessArea = function (ba) {

        if (ba === $scope.selectedBA ) {
            ba = null;
        }
        $scope.selectedBA = ba;

        var eventArray = [];
        var dateArray = [];
        $scope.events = [];

        //Find every monthYear combo where we have an event 
        angular.forEach($scope.masterEvents, function (v) {
            //find the events based on the selected business area
            // or all of the events if a business area isn't selected
            if (v.CustomerSegment === $scope.selectedBA || !$scope.selectedBA) {
                eventArray.push(v);
                var monthYear = new Date(v.EventStartDate).getMonth() + "" + new Date(v.EventStartDate).getFullYear();
                
                //if that month is not added to the list add it
                if (dateArray.indexOf(monthYear) === -1) {
                    dateArray.push(monthYear);
                }
            }
        });
        
        //add an empty object to the events array for every month we have 
        angular.forEach(dateArray, function (v, i) {
            $scope.events.push({
                events: []
            });

            //for every event get the month/year and event info and push it into the array
            angular.forEach(eventArray, function (v2) {

                if (v2.EventStartDate !== "undefined") {
                    var startDate = new Date(v2.EventStartDate)
                    if (v== startDate.getMonth() + "" + startDate.getFullYear()) {
                        $scope.events[i].month = months[startDate.getMonth()] + " " +startDate.getFullYear();

                        //set the color on the customer segment / business area
                        switch (v2.CustomerSegment)
                        {
                            case 'BCS':
                                v2.Color = '#447ebc'
                                break;
                            case 'TS':
                                v2.Color = '#aa6dab'
                                break;
                            case 'CTT':
                                v2.Color = '#ff7900'
                                break;
                            case 'TBCT':
                                v2.Color = '#008D61'
                                break;
                            case 'AB':
                                v2.Color = '#f7403a'
                                break;

                            default:
                                v2.Color = '#447ebc'
                            break;

                        }
                        $scope.events[i].events.push(v2);
                    }
                }
            });
        });
    };

    //function to convert date to the proper format of day month year 
    $scope.convertDate = function (d) {

        if (typeof (d) !== "undefined") {
            var cdate = new Date(d);
            return cdate.getDate() + " " + months[cdate.getMonth()] + " " + cdate.getFullYear();
        }
        else
        {
            console.log("Attempted to format an incorrect date, variable is: " + d)
            return ""
        }

    };

    //list of events
    sharePointService.getEvents().then(function (response) {
        if (response.data.value.length != 0)
        {
        $scope.masterEvents = response.data.value;
        $scope.filterBusinessArea(loadVar);
        }
        else
        {
            $scope.masterEvents = [{
                "Title": "No events found for the current timeframe", 
                "CustomerSegment": "BCS", 
                "Website.URL": "https://www.terumobct.com", 
                "EventStartDate": new Date(),
                "EventEndDate": new Date(),
                 "Location": " "
            }];
            $scope.filterBusinessArea(loadVar);
        }
    }).catch
        //https://stackoverflow.com/questions/23559341/using-success-error-finally-catch-with-promises-in-angularjs
        (function (e) {
            console.log("Error: ", e);
            $scope.masterEvents = [{
                "Title": "No events found", 
                "CustomerSegment": "BCS", 
                "Website.URL": "https://www.terumobct.com", 
                "EventStartDate": new Date(),
                "EventEndDate": new Date(),
                 "Location": " "
            }];
            $scope.filterBusinessArea(loadVar);
            
        });

    //list of Business Area's to filer on
    //if you add a new item to the Event Calendar list, you need to follow this pattern 
    var arrayBA = ["BCS", "CTT", "TS", "TBCT"];

    //if user goes to page with BA in the URL
    var loadVar = null;
    if (arrayBA.indexOf(sharePointService.getUrlVars()['ba']) > -1) {
        loadVar = sharePointService.getUrlVars()['ba'];
    }
    $scope.filterBusinessArea(loadVar);

}]);


app.controller("PressReleaseCtrl", ["$scope", "sharePointService", "arrayService", "$document", function ($scope, sharePointService, arrayService, $document) {

    $scope.convertDate = function (d) {
        if (!d) {
            return "";
        }
        var date = new Date(d);
        return date.getUTCDate() + " " + arrayService.getMonth(date) + " " + date.getFullYear();
    };

    $scope.limit = true;
    $scope.loading = false;
    $scope.getPressReleases = function () {
        $scope.loading = true;
        var limit = $scope.limit ? $scope.limitAmt : null;
        sharePointService.getPressReleases(limit).then(function (resp) {
            $scope.loading = false;
            $scope.pressReleases = resp.data.value;
        });
    };

    $document.ready(function () {
        $scope.getPressReleases();
    });

}]);

app.controller("SitemapCtrl", ["$scope", "sharePointService", function ($scope, sharePointService) {

    sharePointService.getNav().then(function (resp) {
        $scope.ps = resp.productsAndServices;
        $scope.otherData = resp.allOtherData;

    });
}]);