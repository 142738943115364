var app = angular.module("terumo", ["ngTouch", "ngCookies", "tooltip", "pascalprecht.translate", "json-data"])
    .config(["$windowProvider", "$locationProvider", "$compileProvider", "$provide", "$translateProvider",function ($windowProvider, $locationProvider, $compileProvider, $provide, $translateProvider) {
    // Translations
    // using 'sceParameters' as this was the only built-in strategy allowing for html in translated strings
    $translateProvider.useSanitizeValueStrategy('sceParameters');
    $translateProvider.useStaticFilesLoader({
        prefix: '/_catalogs/masterpage/dotcom/locale/tbct-',
        suffix: '.json.txt'
    });

    $translateProvider.preferredLanguage('en-us');
    //speed up angular
    $compileProvider.debugInfoEnabled(false);

    //if page is search results, do not include the #/
    //console.log("pageLayout", $windowProvider.$get().pageLayoutName)
    if ($windowProvider.$get().pageLayoutName === "searchresults") {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $provide.decorator('$browser', function ($delegate) {
            $delegate.onUrlChange = function () {};
            return $delegate;
        });
    }
}])
.run(["$rootScope", "$cookies", "$translate", "$document", "$window", "sharePointService", "$interval", function ($rootScope, $cookies, $translate, $document, $window, sharePointService, $interval) {
    //hide user modal and show country dropdown
    $rootScope.showCountryPickerDropDown = function () {
        $rootScope.userShow = false;
        sharePointService.hideGlobalMessage();
        $rootScope.isCountryPickerShowing = !$rootScope.isCountryPickerShowing;
    };

    //current page
    $rootScope.setUrl = sharePointService.setUrl;

    // Set a global for translation complete    
    $rootScope.i18nLoaded = false;
    $rootScope.$on('$translateChangeSuccess', function() {
        $rootScope.i18nLoaded = true;
        $rootScope.$broadcast('translation-loaded');
    });

    //assign header
    $rootScope.other = $window.pageLayoutName === "home" ? false : true;

    //change path
    $rootScope.masterPath = $window.location.href.indexOf("terumobct") > -1 ? "/_catalogs/masterpage/" : "";

    //global anonymous boolean
    $rootScope.isAnonymous = sharePointService.isAnonymous();

    //check to see if country is global
    var _isGlobalContent= sharePointService.isGlobalContent();
    if(_isGlobalContent===undefined){
        sharePointService.setUserCountry("Global", true);
    }
    if (_isGlobalContent === false) {
        sharePointService.hideGlobalMessage();        
    }
    $rootScope.isGlobalContent = _isGlobalContent;

    // get the features enabled for this user
    sharePointService.requestFeatureToggles().then(function (resp) {

        //set static labels (such as "Products & Services") to correspond to selected language
        sharePointService.setUserLocale();
        $translate.use(sharePointService.getUserLocale());

        // set specific css per language
        sharePointService.switchLanguageCSS(sharePointService.getUserLocale());

        //get the country list
        sharePointService.requestCountryInfo();

        //on reaching the site, if the user is a global user, 
        // and the user has picked a language, redirect them to that site language. 
        var locale = sharePointService.getUserLocale();
        $rootScope.isGlobalJapanese = (locale.toLowerCase()==='ja' && _isGlobalContent);

        if ($rootScope.featureToggles["Global Chinese Selection"]) {
            //if you are a global user and on the home page
            if (_isGlobalContent && $window.location.href == ($window.location.origin || '') + "/") {
                //If a user who has previously selected Chinese visits the root home page, redirect them to "/zh"
                if (locale === 'zh'){
                    $window.location.href = ($window.location.origin || '') + "/" + locale;
                }
            }
            //if the user was previously on a global page but not now 
           if (_isGlobalContent && $window.location.href == ($window.location.origin || '') + "/zh" && locale !== "zh") {
                $window.location.href = ($window.location.origin || '/');
            }
        }

        if ( $rootScope.featureToggles["Global Japanese Selection"]) {
            //if you are a global user and on the home page
            if ($window.location.href == ($window.location.origin || '') + "/") {
                //If a user who has previously selected Chinese/Japanese visits the root home page, redirect them to "/zh or /ja"
                if (locale === 'ja'){
                    $window.location.href = ($window.location.origin || '') + "/" + locale;
                }
            }

           //if the user was previously on a global page but not now 
            if ($window.location.href == ($window.location.origin || '') + "/ja" && locale !== "ja") {
                $window.location.href = ($window.location.origin || '/');
            }
        }

    });


    if ($window.pageLayoutName === "searchresults") {
        angular.element($document[0].getElementById("s4-workspace")).addClass($window.pageLayoutName);

        if ($rootScope.isAnonymous) {
            angular.element(document.getElementById("anonUserMessage")).css("display", "table");
         
            if (sharePointService.hasCountry()) {
                if (!sharePointService.getUrlVars()["Country"]) {
                    $window.location.href = sharePointService.checkForParams($window.location.href);
                } else {
                    if (sharePointService.getUrlVars()["Country"] && decodeURIComponent(sharePointService.getUrlVars()["Country"]) !== sharePointService.getUserCountry()) {
                        $window.location.href = $window.location.href.replace(sharePointService.getUrlVars()["Country"], sharePointService.getUserCountry());
                    }
                    if (!sharePointService.getUrlVars()["k"]) {
                        $window.location.href = $window.location.href + "&k=*";
                    }
                }
            }
        }
    } else {
        $window.addEventListener("hashchange", function () {
            $window.location.reload();
        });
    }

    var kkeys = [];
    var konamiFF = "38,38,40,40,37,39,37,39,98,97";
    var konamiChrome = "38,38,40,40,37,39,37,39,66,65";
    $document.bind('keyup', function (e) {
        kkeys.push(e.charCode || e.keyCode);
        if (kkeys.toString().indexOf(konamiFF) >= 0 || kkeys.toString().indexOf(konamiChrome) >= 0) {
            
            var html = angular.element('<div class="easter-egg"><img src="/_catalogs/masterpage/dotcom/img/Terucolo-Dance.gif"></div>');
            angular.element($document[0].getElementsByTagName('body')).append(html);

            $document.unbind('keyup', arguments.callee);
            var audio = new Audio('/_catalogs/masterpage/dotcom/img/awyeah_terumo.mp3');
            audio.play();
            angular.element($document[0].getElementsByClassName("easter-egg")[0]).addClass("show");
            var count = 0;
            var i = $interval(function () {
                if (count !== 0 && count % 3 === 0) {
                    angular.element($document[0].getElementsByClassName("easter-egg")[0]).find("img").css({
                        left: Math.floor(Math.random() * ($window.innerWidth - 50)) + "px",
                        top: Math.floor(Math.random() * ($window.innerHeight - 50)) + "px"
                    });
                }
                count++;
            }, 250);
            audio.addEventListener('ended', function () {
                angular.element($document[0].getElementsByClassName("easter-egg")[0]).remove();
                $interval.cancel(i);
            });
        }
        if (kkeys.length > konamiFF.length) {
            $document.unbind('keyup', arguments.callee);
        }
    });
}]);